import { Button, List } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { IonItem, IonLabel, IonLoading, IonSelect, IonSelectOption } from '@ionic/react';
import { HourSelector } from '../../../components/HourSelector/HourSelector';
import ReactQuill from 'react-quill';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG } from '../../../utils/constants';
import { NotificationsProps, PkNotificationsConfig } from './types';
import { useEffect, useMemo, useState } from 'react';
import { MINUTES_OPTIONS, defaultNotificationsConfig } from './helpers';
import _ from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useAppContext from '../../../hooks/useAppContext';
import { parkingService } from '../../../_api/services/parking';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { Toggle } from '../../../components/Toggle/Toggle';

export const Notifications: React.FC<NotificationsProps> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;
	const [notificationsConfig, setNotificationsConfig] = useState(defaultNotificationsConfig);
	const [isEdited, setIsEdited] = useState(false);
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [changeQuill, setChangeQuill] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { isLoading: isLoading, data: initialValues } = useQuery(
		['PkNotificationsConfig'],
		async () => parkingService.getPkNotificationConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setNotificationsConfig(data);
			},
		},
	);

	const { isLoading: loadingSave, mutate: handleSave } = useMutation(parkingService.updatePkNotificationConfig, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('PkNotificationsConfig');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const emailBody = useMemo(() => {
		return notificationsConfig.emailTranslations.find((translate) => translate.language === selectedLang).body;
	}, [notificationsConfig, selectedLang]);
	const isTranslationInvalid = (translation: string): boolean => {
		return _.isEmpty(translation) || translation === '<p><br></p>';
	};
	// Handlers
	const handleNotificationsIsActiveChange = () => {
		const newConfig = {
			...notificationsConfig,
			sendNotificationEnabled: !notificationsConfig.sendNotificationEnabled,
		};
		setIsEdited(!_.isEqual(newConfig, initialValues));
		setNotificationsConfig(newConfig);
	};

	const handleEmailIsActiveChange = () => {
		const newConfig = { ...notificationsConfig, sendEmailEnabled: !notificationsConfig.sendEmailEnabled };
		setIsEdited(!_.isEqual(newConfig, initialValues));
		setNotificationsConfig(newConfig);
	};

	const handleNotificationsPreviousTimeChange = (e) => {
		const newConfig = { ...notificationsConfig, minutesBeforeEndNotification: e.target.value };
		setIsEdited(!_.isEqual(newConfig, initialValues));
		setNotificationsConfig(newConfig);
	};

	const handleEmailPreviousTimeChange = (e) => {
		const newConfig = { ...notificationsConfig, minutesBeforeEndEmail: e.target.value };
		setIsEdited(!_.isEqual(newConfig, initialValues));
		setNotificationsConfig(newConfig);
	};

	const handleEndHourChange = (e) => {
		const newConfig = { ...notificationsConfig, doNotSendAfter: e.target.value };
		setIsEdited(!_.isEqual(newConfig, initialValues));
		setNotificationsConfig(newConfig);
	};

	const handleEmailTextChange = (e) => {
		if (changeQuill) {
			const newTranslation = notificationsConfig.emailTranslations.map((translation) =>
				translation.language === selectedLang ? { ...translation, body: e } : translation,
			);
			const newConfig = { ...notificationsConfig, emailTranslations: newTranslation };
			setIsEdited(!_.isEqual(newConfig, initialValues));
			setNotificationsConfig(newConfig);
		}
	};
	const completeAllTranslations = (config: PkNotificationsConfig): PkNotificationsConfig => {
		const isAnyBodyEmpty = config.emailTranslations.some(
			(translation) => translation.body.trim() === '' || translation.body === '<p><br></p>',
		);
		const translationForAll = config.emailTranslations.find((t) => t.language === selectedLang).body;
		if (isAnyBodyEmpty && !_.isEmpty(translationForAll)) {
			const newTranslation = config.emailTranslations.map((translation) =>
				translation.body === '' || translation.body === '<p><br></p>'
					? { ...translation, body: translationForAll }
					: translation,
			);
			const newConfig = { ...notificationsConfig, emailTranslations: newTranslation };
			return newConfig;
		}
		return config;
	};
	const handleSaveConfig = () => {
		handleSave(completeAllTranslations(notificationsConfig));
		setIsEdited(false);
	};
	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
					<List>
						<header className={`${styles.h2} ${styles.headerSpace}`}>{t('title_pk_notifications')}</header>
						{/* TOGGLES */}
						<div className={`${styles.element} ${styles.toggles}`}>
							<div className={styles.toggleItem}>
								<Toggle
									checked={notificationsConfig.sendNotificationEnabled}
									onChange={handleNotificationsIsActiveChange}
									disabled={!hasWritePermission}
								/>
								<IonLabel className={`${styles.footnote} ${styles.space}`}>
									{t('lbl_pk_notifications_toggles.notification')}
								</IonLabel>
							</div>
							<div className={styles.toggleItem}>
								<Toggle
									checked={notificationsConfig.sendEmailEnabled}
									onChange={handleEmailIsActiveChange}
									disabled={!hasWritePermission}
								/>
								<IonLabel className={`${styles.footnote} ${styles.space}`}>
									{t('lbl_pk_notifications_toggles.email')}
								</IonLabel>
							</div>
						</div>
						{/* MINUTES BEFORE NOTIFICATION*/}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_minutesBeforeEnd.notification')}
							</div>
							<IonItem
								lines="none"
								className={`${styles.inputModal} ${
									notificationsConfig.minutesBeforeEndNotification !==
										defaultNotificationsConfig.minutesBeforeEndNotification && styles.touchedInput
								}`}
								disabled={!hasWritePermission || !notificationsConfig.sendNotificationEnabled}
							>
								<IonLabel slot="start" className="lblSelector">
									{t('label_minutes')}
								</IonLabel>
								<IonSelect
									slot="end"
									className={`lblSelector ${styles.selector}`}
									interface="popover"
									value={notificationsConfig.minutesBeforeEndNotification}
									onIonChange={handleNotificationsPreviousTimeChange}
									aria-label={t('label_minutes')}
								>
									{MINUTES_OPTIONS.map((option, index) => {
										return (
											<IonSelectOption key={index} value={option}>
												{option}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</div>
						{/* MINUTES BEFORE EMAIL*/}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_pk_notifications_minutesBeforeEnd.email')}
							</div>
							<IonItem
								lines="none"
								className={`${styles.inputModal} ${
									notificationsConfig.minutesBeforeEndEmail !==
										defaultNotificationsConfig.minutesBeforeEndEmail && styles.touchedInput
								}`}
								disabled={!hasWritePermission || !notificationsConfig.sendEmailEnabled}
							>
								<IonLabel slot="start" className="lblSelector">
									{t('label_minutes')}
								</IonLabel>
								<IonSelect
									slot="end"
									className={`lblSelector ${styles.selector}`}
									interface="popover"
									value={notificationsConfig.minutesBeforeEndEmail}
									onIonChange={handleEmailPreviousTimeChange}
									aria-label={t('label_minutes')}
								>
									{MINUTES_OPTIONS.map((option, index) => {
										return (
											<IonSelectOption key={index} value={option}>
												{option}
											</IonSelectOption>
										);
									})}
								</IonSelect>
							</IonItem>
						</div>
						{/* DO NO SEND AFTER */}
						<div className={styles.element}>
							<div className={styles.blockSubtitle}>{t('lbl_pk_notifications_doNotSendAfter')}</div>
							<HourSelector
								name="doNotSendAfter"
								label={t('lbl_hour_selector')}
								value={notificationsConfig.doNotSendAfter}
								disabled={
									!hasWritePermission ||
									(!notificationsConfig.sendEmailEnabled && !notificationsConfig.sendNotificationEnabled)
								}
								onChange={handleEndHourChange}
							/>
						</div>
						{/* EMAIL BODY */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>{t('lbl_pk_notifications_emailBody')}</div>
							<ReactQuill
								id="pkEmailNotificationBody"
								theme="snow"
								modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
								className={styles.quill}
								value={emailBody}
								onChange={handleEmailTextChange}
								onFocus={() => setChangeQuill(true)}
								readOnly={!hasWritePermission}
								// onBlur={handleAllTranslations}
							/>
						</div>
					</List>
					{/* -------- FOOTER -------------------------------------------------------- */}
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button
								onClick={handleSaveConfig}
								className={styles.btnHeader}
								disabled={
									!isEdited ||
									isTranslationInvalid(
										notificationsConfig.emailTranslations.find(
											(translate) => translate.language === selectedLang,
										).body,
									)
								}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
				</>
			)}
		</>
	);
};
