import { ErrorManagementConfig, PermissionsAndDistricts, ReservationsConfig, TurningPointsConfig } from './types';

export const exampleDistricts = [
	{
		districtName: 'TIC',
		floorName: 'Planta 1',
		floorNumber: 1,
		buildingName: 'Edificio 1',
		overflowDistricts: ['Contabilidad', 'RRHH'],
	},
	{
		districtName: 'RRHH',
		floorName: 'Planta -1',
		floorNumber: -1,
		buildingName: 'Edificio 1',
		overflowDistricts: ['Contabilidad', 'TIC'],
	},
	{
		districtName: 'Contabilidad',
		floorName: 'Planta 1',
		floorNumber: 1,
		buildingName: 'Edificio 3',
		overflowDistricts: ['RRHH', 'TIC'],
	},
	{
		districtName: 'Recursos Humanos',
		floorName: 'Planta 3',
		floorNumber: 3,
		buildingName: 'Edificio 4',
		overflowDistricts: [],
	},
	{ districtName: 'Banca', floorName: 'Planta 4', floorNumber: 4, buildingName: 'Edificio 4', overflowDistricts: [] },
	{ districtName: 'UX', floorName: 'Planta 1', floorNumber: 1, buildingName: 'Edificio 4', overflowDistricts: [] },
	{
		districtName: 'Arquitectura',
		floorName: 'Planta 4',
		floorNumber: 4,
		buildingName: 'Edificio 5',
		overflowDistricts: [],
	},
	{
		districtName: 'Front-End',
		floorName: 'Planta 1',
		floorNumber: 1,
		buildingName: 'Edificio 4',
		overflowDistricts: ['Back-End'],
	},
	{
		districtName: 'Back-End',
		floorName: 'Planta 2',
		floorNumber: 2,
		buildingName: 'Edificio 4',
		overflowDistricts: ['Front-End'],
	},
];

export const isDefaultValues: PermissionsAndDistricts = {
	lastMinuteReservationsConfiguration: 3,
	deskReservationConfiguration: [
		{
			id: 1,
			configurationName: 'SAME_DESK_CONFIGURATION',
			isActive: true,
			headOffice: 1,
		},
		{
			id: 2,
			configurationName: 'SAME_ZONE_CONFIGURATION',
			isActive: false,
			headOffice: 1,
		},
	],
	districts: exampleDistricts,
	restrictedLevels: ['Nivel 1', 'Nivel 2'],
};

export const mockReservationsConfig: ReservationsConfig = {
	policies: [
		{
			id: 1,
			name: 'Dia completo',
			fullHoraMin: '00:00',
			fullHoraMax: '23:59',
			isActive: true,
			isDefault: true,
		},
		{
			id: 2,
			name: 'Morning',
			fullHoraMin: '00:00',
			fullHoraMax: '14:00',
			isActive: true,
			isDefault: false,
		},
		{
			id: 3,
			name: 'Tarde',
			fullHoraMin: '14:00',
			fullHoraMax: '23:59',
			isActive: true,
			isDefault: false,
		},
		{
			id: 4,
			name: 'Personalizada',
			fullHoraMin: '08:00',
			fullHoraMax: '20:00',
			isActive: true,
			isDefault: false,
		},
	],
	maxFavoriteDesks: 11,
	lastHistoricDesks: 10,
	favouriteDesksRestriction: false,
	isPresenceMandatory: false,
};

export const mockTurningPointsConfig: TurningPointsConfig = {
	buildings: [
		{
			buildingId: 1,
			buildingName: 'Edificio 1',
			dayOfWeek: [
				{
					day: 2, // 0 = sunday
					hour: '12:00',
				},
			],
			numberOpenDays: 5, // can be 0
		},
		{
			buildingId: 2,
			buildingName: 'Edificio 2',
			dayOfWeek: [
				{
					day: 2, // 0 = sunday
					hour: '12:00',
				},
			],
			numberOpenDays: 5, // can be 0
		},
	],
	usersToSendEmail: [50048241, 50057817, 50014089], // can be []
	visitsBuilding: {
		buildingId: 1,
		buildingName: 'Edificio 1',
	}, // can not be null
};

export const mockErrorManagementConfig: ErrorManagementConfig = {
	responseTime: 5,
	numberOfFailures: 4,
	timeInterval: 30,
	usersToSendEmail: [],
};

export const mockSedeLevels = [
	'NIVEL 3',
	'Director',
	'Nivel 2',
	'Nivel 1',
	'Nivel 4',
	'Nivel 6',
	'Nivel 5',
	'Nivel 7',
	'Nivel 8',
];
