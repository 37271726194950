import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { EditReservationItem } from '../EditReservationItem/EditReservationItem';
import { ReservationItem } from '../../../../../_api/services/parking/types';

type Props = {
	reservations: ReservationItem[];

	reservationType: 'reservation' | 'request';
	onChange: (reservationId: number, vehicleId: number) => void;
};

export const EditReservationItemsList = (props: Props) => {
	const { reservations, reservationType, onChange } = props;
	const { t } = useTranslation();

	const textNoReservations =
		reservationType === 'reservation' ? t('user_does_not_have_reservations') : t('user_does_not_have_requests');

	return (
		<>
			{_.isEmpty(reservations) ? (
				<p>{textNoReservations}</p>
			) : (
				reservations
					.sort((a, b) => new Date(a.reservationDate).getTime() - new Date(b.reservationDate).getTime())
					.map((reservation: any) => {
						const date = reservation.reservationDate;
						const schedule = reservation.reservationType;

						return (
							<EditReservationItem
								key={reservation.reservationId}
								reservationId={reservation.reservationId}
								reservationType="reservation"
								date={date}
								schedule={schedule}
								selectedVehicle={reservation.vehicles.find((vehicle) => vehicle.selected)}
								userVehicles={reservation.vehicles}
								onChange={onChange}
							/>
						);
					})
			)}
		</>
	);
};
