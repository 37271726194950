import { useEffect, useState } from 'react';
import { RefresherEventDetail, IonLoading } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import { Toast, Alert, Button } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { incidentServices } from '../../../_api/services/incidents';
import CategoriesModal from './components/CategoriesModal/CategoriesModal';
import SubCategoriesModal from './components/SubcategoriesModal/SubcategoriesModal';
import { Subcategories, Subcategory } from '../../../_api/services/incidents/types';
import useAppContext from '../../../hooks/useAppContext';
import { LanguageSelector } from '../../../components/LanguageSelector/LanguageSelector';
import { FilterChipsBar } from '../../../components/FilterChipsBar/FilterChipsBar';
import { arraymove } from '../../../utils/functions';
import { EditableList } from '../../../components/EditableList/EditableList';
import styles from './styles.module.scss';

export type Props = {
	hasWritePermission: boolean;
	setError: (msg: string) => void;
	setSuccess: (msg: string) => void;
	footerStyle: string;
};

export const Categories: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedCategory, setSelectedCategory] = useState(0);
	const [selectedLang, setSelectedLang] = useState('es');
	const [selectedSubcat, setSelectedSubcat] = useState<Subcategory[]>(null);
	const [subcatId, setSubcatId] = useState(0);
	const [showCategories, setShowCategories] = useState(false);
	const [alertDelSC, setAlertDelSC] = useState(false);
	const [showSubCategories, setShowSubCategories] = useState(false);
	const [categoriesState, setCategoriesState] = useState<any>(null);
	const [subcatState, setSubcatState] = useState<Subcategory[]>(null);
	const [isEditedSubcategories, setIsEditedSubCategories] = useState(false);
	const [isEditedCategories, setIsEditedCategories] = useState(false);
	const [saveChangesMessage, setSaveChangesMessage] = useState('');

	useEffect(() => {
		setThereAreUnsavedChanges(isEditedCategories || isEditedSubcategories);
	}, [isEditedCategories || isEditedSubcategories]);

	const { data: categories, isLoading } = useQuery(
		['categories'],
		async () => await incidentServices.getCategories(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);
	const { data: subcategories, isLoading: loadingSubcats } = useQuery<Subcategories[]>(
		['subcategories'],
		async () => await incidentServices.getSubcategories(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => props.setError(error as string),
		},
	);

	const { mutate: saveCategories, isLoading: loadingSaveCat } = useMutation(incidentServices.saveCategories, {
		onSuccess: (data) => {
			if (isEditedSubcategories) {
				const maxIncidenceId = _.maxBy(subcategories, function (o) {
					return o.incidenceId;
				}).incidenceId;

				saveSubCategories(
					subcatState.map((s) => {
						return {
							...s,
							categoryId: _.first(data.filter((d) => d.idInsert === s.categoryId))?.categoryId,
							incidenceId: s.incidenceId > maxIncidenceId ? null : s.incidenceId,
						};
					}),
				);
			} else {
				queryClient.refetchQueries('categories');
				setIsEditedCategories(false);
				props.setSuccess(t('msg_success'));
			}
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	const { mutate: saveSubCategories, isLoading: loadingSaveSubCat } = useMutation(incidentServices.saveSubCategories, {
		onSuccess: () => {
			queryClient.refetchQueries('subcategories');
			setIsEditedSubCategories(false);
			props.setSuccess(t('msg_success'));
		},
		onError: (error) => {
			props.setError(error as string);
		},
	});

	useEffect(() => {
		categories && setCategoriesState(_.sortBy(categories, 'ordinal'));
	}, [categories]);

	useEffect(() => {
		subcategories && setSelectedSubcat(subcategories.filter((e) => e.categoryId === selectedCategory));
		subcategories && setSubcatState(_.sortBy(subcategories, 'ordinal'));
	}, [subcategories]);

	useEffect(() => {
		subcatState && setSelectedSubcat(subcatState.filter((e) => e.categoryId === selectedCategory));
	}, [selectedCategory]);

	useEffect(() => {
		categoriesState && setSelectedCategory(_.first(_.sortBy(categoriesState, 'ordinal'))?.categoryId);
		setIsEditedCategories(!_.isEqual(_.sortBy(categories, 'ordinal'), _.sortBy(categoriesState, 'ordinal')));
	}, [categoriesState]);

	useEffect(() => {
		setIsEditedSubCategories(
			!_.isEqual(_.sortBy(subcategories, 'categoryId', 'ordinal'), _.sortBy(subcatState, 'categoryId', 'ordinal')),
		);
	}, [subcategories, subcatState]);

	const doRefresh = (event: CustomEvent<RefresherEventDetail>) => {
		event.detail.complete();
		const action = event.detail as any;

		const changesMoved = _.sortBy(
			arraymove(
				_.sortBy(_.filter(subcatState, { categoryId: selectedCategory }), 'ordinal'),
				action.from,
				action.to,
			).map((e: Subcategory, index) => {
				return {
					...e,
					ordinal: index + 1,
				};
			}),
			'ordinal',
		);
		setSubcatState(
			_.union(
				subcatState.filter((e) => e.categoryId !== selectedCategory),
				changesMoved,
			),
		);
	};

	const handleEditSubcat = (id: number) => {
		setSubcatId(id);
		setShowSubCategories(true);
	};

	const handleDeleteSubcat = (id: number) => {
		setSubcatId(id);
		setAlertDelSC(true);
	};

	const handleAddSubcat = () => {
		handleEditSubcat(0);
	};

	const deleteSubcat = () => {
		const ordinal = _.first(selectedSubcat.filter((c) => c.incidenceId === subcatId)).ordinal;
		const selectedSC = selectedSubcat.map((sc) => {
			if (sc.incidenceId === subcatId) {
				return { ...sc, disabled: true, ordinal: null };
			} else {
				if (sc.ordinal > ordinal) {
					return { ...sc, ordinal: sc.ordinal - 1 };
				} else {
					return sc;
				}
			}
		});
		setSubcatState(
			_.union(
				subcatState.filter((e) => e.categoryId !== selectedCategory),
				selectedSC,
			),
		);
		setSelectedSubcat(selectedSC);
	};

	const handleSave = () => {
		const categoriesIds = !categories ? [0] : categories.map((c) => c.categoryId);
		const incidencesIds = !subcategories ? [0] : categories.map((c) => c.incidenceId);
		const maxCategoryId = Math.max(...categoriesIds);
		const maxIncidenceId = Math.max(...incidencesIds);

		if (isEditedCategories) {
			saveCategories(
				categoriesState.map((c) => {
					return {
						...c,
						categoryId: c.categoryId > maxCategoryId ? null : c.categoryId,
						idInsert: c.categoryId,
					};
				}),
			);
		} else if (isEditedSubcategories) {
			saveSubCategories(
				subcatState.map((s) => {
					const incidenceId = s.incidenceId ? s.incidenceId : null;
					return {
						...s,
						incidenceId: incidenceId > maxIncidenceId ? null : incidenceId,
					};
				}),
			);
		}
	};

	const handleSaveChangesCategories = (changes) => {
		setCategoriesState(changes);
		setSaveChangesMessage(t('msg_save_categorie'));
	};
	const handleSaveChangesSubCategories = (changes) => {
		setSubcatState(
			_.union(
				subcatState.filter((e) => e.categoryId !== selectedCategory),
				[...selectedSubcat.filter((c) => c.incidenceId !== subcatId), changes],
			),
		);
		setSelectedSubcat([...selectedSubcat.filter((c) => c.incidenceId !== subcatId), changes]);
		setSaveChangesMessage(t('msg_save_subcategorie'));
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<FilterChipsBar
				selectedLang={selectedLang}
				selectedCategory={selectedCategory}
				setSelectedCategory={setSelectedCategory}
				categoriesState={categoriesState}
				setShowCategories={setShowCategories}
				keyField="categoryId"
			/>
			<EditableList
				title={t('subcategories')}
				titleClassName={styles.h3}
				hasWritePermission={props.hasWritePermission}
				selectedLang={selectedLang}
				data={selectedSubcat}
				keyField="incidenceId"
				loading={loadingSubcats}
				onItemReorder={doRefresh}
				onEdit={handleEditSubcat}
				onDelete={handleDeleteSubcat}
				onAdd={handleAddSubcat}
				buttonAddText={t('btn_add_subcategory')}
				buttonAddPosition="end"
			/>
			{props.hasWritePermission ? (
				<div className={`${props.footerStyle} ${styles.footerButton}`}>
					<Button
						disabled={!(isEditedCategories || isEditedSubcategories)}
						color="primary"
						className={styles.btnHeader}
						onClick={handleSave}
					>
						{t('btn_save_data')}
					</Button>
				</div>
			) : null}
			<CategoriesModal
				categories={categoriesState}
				showModal={showCategories}
				onClose={() => setShowCategories(false)}
				hasWritePermission={props.hasWritePermission}
				saveChanges={handleSaveChangesCategories}
				lang={selectedLang}
			/>
			<SubCategoriesModal
				subcategory={selectedSubcat?.filter((c) => c.incidenceId === subcatId)}
				categoryId={selectedCategory}
				ordinal={
					!_.isEmpty(selectedSubcat)
						? _.maxBy(selectedSubcat, function (o) {
								return o.ordinal;
						  })?.ordinal + 1
						: 1
				}
				idSubCat={
					subcatState
						? _.maxBy(subcatState, function (o) {
								return o.incidenceId;
						  })?.incidenceId + 1
						: null
				}
				showModal={showSubCategories}
				onClose={() => setShowSubCategories(false)}
				hasWritePermission={props.hasWritePermission}
				saveChanges={(changes) => handleSaveChangesSubCategories(changes)}
				lang={selectedLang}
			/>
			<IonLoading
				isOpen={isLoading || loadingSaveCat || loadingSaveSubCat}
				message={t('msg_loading')}
				duration={1500}
			/>
			<Alert
				isOpen={alertDelSC}
				onDidDismiss={() => setAlertDelSC(false)}
				header={t('delete_subcategory')}
				message={t('confirm_delete_subcategory')}
				buttons={[
					{ text: 'No', role: 'cancel' },
					{ text: t('lbl_affirmative'), handler: () => deleteSubcat() },
				]}
				mode="ios"
			/>
			<Toast
				isOpen={!!saveChangesMessage}
				message={saveChangesMessage}
				onDidDismiss={() => setSaveChangesMessage('')}
				position="bottom"
				type="success"
			/>
		</>
	);
};
