import i18n from '../../i18nextConf';
import _ from 'lodash';
import { AppPermission, AppPermissionsData, Employee, Profile } from '../../_api/services/roles/types';
import { MenuItem } from '../../_api/services/generalServices/types';
import { USER_APP_PERMISSIONS_TYPES } from '../../utils/constants';
import { diacriticalReplace, getDateParts } from '../../utils/functions';

export const fixedPermissionsList = [
	'desks',
	'parking',
	'pooler_moto',
	'pooler_microcar',
	'noCheckParking',
	'room',
	'incidents',
	'emergency',
	'search',
	'notifications',
	'news',
	'wallet',
];

export const getFixedPermissionsList = (permissions: string[]): AppPermission[] =>
	permissions.map((permission, index) => ({
		id: -100 + index,
		permission: permission,
		tab: i18n.t(`app_permissions.${permission}.tab`),
		profile_permission_subtitle: i18n.t(`app_permissions.${permission}.profile_permission_subtitle`),
		individual_permission_subtitle: i18n.t(`app_permissions.${permission}.individual_permission_subtitle`),
		modal: i18n.t(`app_permissions.${permission}.modal`),
	}));

export const getServicesPermissionsList = (permissions: MenuItem[]): AppPermission[] => {
	return permissions.map((permission) => {
		const configuredPermission = {
			id: permission.id,
			permission: permission.permission,
			tab: '',
			profile_permission_subtitle: '',
			individual_permission_subtitle: '',
			modal: '',
		};

		if (_.isEmpty(permission?.translations)) {
			return configuredPermission;
		}

		const translation =
			permission.translations.find((translation) => translation.language === i18n.language) ??
			permission.translations[0];

		if (!translation) {
			return configuredPermission;
		}

		configuredPermission.tab = translation.text;
		configuredPermission.profile_permission_subtitle = i18n.t(
			`app_permissions.other_services.profile_permission_subtitle`,
			{
				permission: translation.text,
			},
		);
		configuredPermission.individual_permission_subtitle = i18n.t(
			`app_permissions.other_services.individual_permission_subtitle`,
			{
				permission: translation.text,
			},
		);
		configuredPermission.modal = i18n.t(`app_permissions.other_services.modal`, {
			permission: translation.text,
		});

		return configuredPermission;
	});
};

export const getPermissionsList = (servicesPermissionsList: MenuItem[]) => {
	const fixedPermissions = getFixedPermissionsList(fixedPermissionsList);
	const servicesPermissions = getServicesPermissionsList(servicesPermissionsList).sort((a, b) => a.id - b.id);
	return [...fixedPermissions, ...servicesPermissions];
};

export const matchesUserSearch = (search: string, user: Employee) => {
	return (
		_.isEmpty(search) ||
		diacriticalReplace(`${user.employeeId} - ${user.fullName}`)
			.toLowerCase()
			.includes(diacriticalReplace(search).toLowerCase())
	);
};

export const getFilteredUsers = (dataUsers: Employee[], showOnlyCheckeds: boolean, userNameSearch: string) => {
	if (_.isEmpty(dataUsers)) {
		return [];
	}

	const result = dataUsers.filter((user) => {
		const show = !showOnlyCheckeds || (showOnlyCheckeds && user.isChecked);
		return show && matchesUserSearch(userNameSearch, user);
	});

	return result.sort((a, b) => {
		return diacriticalReplace(a.fullName).localeCompare(diacriticalReplace(b.fullName));
	});
};

export const getUserData = (dataUsers: Employee[], currentUsers: number[]): Employee[] => {
	return (
		dataUsers.map((user) => {
			const currentlyHasPermission = currentUsers.includes(user.employeeId);
			return {
				...user,
				isChecked: currentlyHasPermission,
				visible: true,
				disabled: currentlyHasPermission,
			};
		}) ?? []
	);
};

export const getUsersDataForEdition = (dataUsers: Employee[]): Employee[] => {
	return (
		dataUsers.map((user) => ({
			...user,
			isChecked: true,
			visible: true,
		})) ?? []
	);
};

export const getPlannedDate = (startDate: string, endDate: string, permissionType: string) => {
	if (startDate && endDate && permissionType === 'temporal') {
		const startDateArr = getDateParts(startDate);
		const endDateArr = getDateParts(endDate);
		if (!startDateArr || !endDateArr) {
			return '-';
		}
		const [year1, month1, day1] = startDateArr;
		const [year2, month2, day2] = endDateArr;
		return i18n.t('app_permissions.planned_dates.interval', {
			year1,
			month1,
			day1,
			year2,
			month2,
			day2,
		});
	} else if (startDate && permissionType === 'start_date') {
		const startDateArr = getDateParts(startDate);
		if (!startDateArr) {
			return '-';
		}
		const [year, month, day] = startDateArr;
		return i18n.t('app_permissions.planned_dates.start_date', {
			year,
			month,
			day,
		});
	} else {
		return i18n.t(`app_permissions.permission_types.${permissionType}`);
	}
};

export const employeeHasChanges = (initial: Employee, updated: Employee) => {
	if (initial.permissionType !== updated.permissionType || updated?.deleted) {
		return true;
	}

	if (updated?.startDate && initial?.startDate !== updated.startDate) {
		return true;
	}

	if (updated?.endDate && initial?.endDate !== updated.endDate) {
		return true;
	}

	return false;
};

export const getProfilesChanges = (
	initial: Profile[],
	selectedProfilesIds: number[],
	profilesList: Profile[],
): Profile[] => {
	const changes = [];
	const initialProfilesIds = initial.map((profile) => profile.profileId);

	if (profilesList && !_.isEmpty(profilesList)) {
		profilesList.forEach((profile) => {
			const profileIsAdded =
				selectedProfilesIds.includes(profile.profileId) && !initialProfilesIds.includes(profile.profileId);
			const profileIsDeleted =
				!selectedProfilesIds.includes(profile.profileId) && initialProfilesIds.includes(profile.profileId);

			if (profileIsAdded) {
				changes.push({
					...profile,
					deleted: false,
				});
			} else if (profileIsDeleted) {
				changes.push({
					...profile,
					deleted: true,
				});
			}
		});
	}

	return changes;
};

export const getEmployeeChanges = (initial: Employee[], updated: Employee[]) => {
	const changes: Employee[] = [];

	const initialIndexed = {};

	if (initial && !_.isEmpty(initial)) {
		initial.forEach((item) => {
			initialIndexed[item.employeeId] = {
				...item,
				deleted: false,
			};
		});

		initial.forEach((item) => {
			if (!updated.find((u) => u.employeeId === item.employeeId)) {
				changes.push({
					...item,
					deleted: true,
				});
			}
		});
	}

	if (updated && !_.isEmpty(updated)) {
		processUpdatedItems(updated, initialIndexed, changes);
	}

	return changes;
};

const processUpdatedItems = (updated, initialIndexed, changes) => {
	updated.forEach((item) => {
		if (!initialIndexed[item.employeeId] || employeeHasChanges(initialIndexed[item.employeeId], item)) {
			changes.push(item);
		}
	});
};

export const checkIsEdited = (
	initialData: AppPermissionsData,
	currentUsersData: Employee[],
	selectedProfiles: number[],
	profilesList: Profile[],
) => {
	const initialProfiles = initialData?.profiles ?? [];
	const initialEmployees = initialData?.employees ?? [];

	if (initialProfiles.length !== selectedProfiles.length) {
		return true;
	}
	if (initialEmployees.length !== currentUsersData.length) {
		return true;
	}
	if (getProfilesChanges(initialProfiles, selectedProfiles, profilesList).length > 0) {
		return true;
	}
	if (getEmployeeChanges(initialEmployees, currentUsersData).length > 0) {
		return true;
	}
	return false;
};

export const getUpdatedUsersData = (dataUsers: Employee[]): Employee[] => {
	if (!dataUsers || _.isEmpty(dataUsers)) {
		return [];
	}

	return dataUsers
		.filter((u) => u.isChecked && !u.disabled)
		.map((user) => ({
			permissionType: user.permissionType ?? USER_APP_PERMISSIONS_TYPES.undefined,
			startDate: [USER_APP_PERMISSIONS_TYPES.start_date, USER_APP_PERMISSIONS_TYPES.temporal].includes(
				user.permissionType,
			)
				? user.startDate
				: '',
			endDate: user.permissionType === USER_APP_PERMISSIONS_TYPES.temporal ? user.endDate : '',
			employeeId: user.employeeId,
			fullName: user.fullName,
			profile: user?.profile ?? '',
		}));
};
