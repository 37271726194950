import muleSoftInstance from '../../utils/httpCommon';
import { Category, Subcategory } from './types';

const getCategories = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/incidences/categories`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getSubcategories = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/incidences`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveCategories = async (categories: Category[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/incidences/categories`;
	try {
		const resp = await muleSoftInstance.put(path, categories);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const saveSubCategories = async (subCategories: Subcategory[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/incidences`;
	try {
		const resp = await muleSoftInstance.put(path, subCategories);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getPhoneEmergency = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/incidences/emergencyPhone`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const savePhoneEmergency = async (phone: string): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/incidences/emergencyPhone`;
	try {
		const resp = await muleSoftInstance.put(path, { value: phone });

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const incidentServices = {
	getCategories,
	getSubcategories,
	saveCategories,
	saveSubCategories,
	getPhoneEmergency,
	savePhoneEmergency,
};
