import { Alert, Button, Icon } from '@acciona/ui-ionic-kit';
import { IonButton, IonLabel, IonLoading } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import { Shuttle } from '../../../../_api/services/generalServices/types';
import { generalServicesService } from '../../../../_api/services/generalServices';
import useAppContext from '../../../../hooks/useAppContext';
import { LanguageSelector } from '../../../../components/LanguageSelector/LanguageSelector';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG, LANGUAGES } from '../../../../utils/constants';
import { getDefaultShuttleName, validateData } from './helpers';
import { zeroPad } from '../../../../utils/functions';
import styles from './styles.module.scss';
import { TextInput } from '../../../../components/TextInput/TextInput';

export type Props = {
	hasWritePermission: boolean;
	setError: (errorMessage: string) => void;
	setSuccess: (errorMessage: string) => void;
	footerStyle: string;
};

export const Shuttles: React.FC<Props> = (props) => {
	const { hasWritePermission, setError, setSuccess, footerStyle } = props;
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [shuttleId, setShuttleId] = useState(0);
	const [showAlertDelete, setShowAlertDelete] = useState(false);
	const [changeQuill, setChangeQuill] = useState(false);
	const [shuttles, setShuttles] = useState<Shuttle[]>([]);
	const [isEdited, setIsEdited] = useState(false);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const { data: initialData, isLoading: isLoading } = useQuery<Shuttle[]>(
		['shuttles'],
		async () => await generalServicesService.getShuttles(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				const filteredData = _.isEmpty(data) ? [] : data.filter((item) => !_.isEmpty(item?.translations));
				setShuttles(
					_.isEmpty(filteredData)
						? []
						: filteredData.map((shuttle) => {
								return { ...shuttle, new: false, edited: false };
						  }),
				);
			},
		},
	);

	const { mutate: handleSave, isLoading: loadingSave } = useMutation(generalServicesService.saveShuttles, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries('shuttles');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const handleDeleteShuttle = (id: number) => {
		setShuttleId(id);
		setShowAlertDelete(true);
	};

	const deleteShuttle = () => {
		let nextShuttles = [];

		const shuttleToDeleteIsInInitialData = shuttleId <= _.maxBy(initialData, (o) => o.id).id;

		if (shuttleToDeleteIsInInitialData) {
			nextShuttles = shuttles?.map((shuttle) => {
				return shuttle.id === shuttleId ? { ...shuttle, enabled: false, edited: true } : shuttle;
			});
		} else {
			nextShuttles = shuttles?.filter((s) => s.id !== shuttleId);
		}

		setShuttles(nextShuttles);
		setIsEdited(true);
	};

	const changeEditItem = (id, item, value) => {
		if (
			_.first(_.filter(shuttles, (o) => o.id === id)).translations.find((e) => e.language == selectedLang)[item] !==
			value
		) {
			if (item === 'schedule') {
				changeQuill && setIsEdited(true);
			} else {
				setIsEdited(true);
			}
			const shuttleEdited = {
				..._.first(_.filter(shuttles, (o) => o.id === id)),
				edited: true,
			};

			shuttleEdited.translations.find((e) => e.language == selectedLang)[item] = value;

			setShuttles([..._.filter(shuttles, (o) => o.id !== id), shuttleEdited]);
		}
	};

	const handleAddShuttle = () => {
		if (!validateData(shuttles)) {
			props.setError(t('msg_error_all_fields_required'));
			return;
		}

		const newShuttleTranslations = LANGUAGES.map((lang) => {
			return { language: lang, name: '', schedule: '' };
		});

		setShuttles([
			...shuttles,
			{
				id: _.isEmpty(shuttles)
					? 1
					: _.maxBy(shuttles, (o) => {
							return o.id;
					  }).id + 1,
				default: zeroPad(shuttles.length + 1, 2),
				name: '',
				schedule: '',
				nameEn: '',
				scheduleEn: '',
				enabled: true,
				edited: false,
				new: true,
				translations: newShuttleTranslations,
			},
		]);
	};
	const handleSaveData = () => {
		if (!validateData(shuttles)) {
			props.setError(t('msg_error_all_fields_required'));
			return;
		}

		handleSave(
			shuttles
				?.filter((e) => e.edited || e.new)
				.map((e) => {
					e?.translations.map((tr) => {
						if (_.isEmpty(tr.name)) tr.name = e?.translations?.find((l) => !_.isEmpty(l.name)).name;
						if (_.isEmpty(tr.schedule))
							tr.schedule = e?.translations?.find((l) => !_.isEmpty(l.schedule)).schedule;
					});

					return {
						id: e.new ? null : e.id,
						enabled: e.enabled,
						translations: e.translations,
					};
				}),
		);
	};

	return (
		<>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<p className={styles.footnote}>{t('shuttle_text_header')}</p>
			{_.sortBy(
				shuttles?.filter((s) => s.enabled),
				'id',
			).map((item) => {
				const shuttleData = item?.translations?.find((e) => e.language == selectedLang) ?? null;
				if (shuttleData) {
					const defaultName = getDefaultShuttleName(initialData, item, selectedLang);
					return (
						<div key={item.id}>
							<div className={styles.h2}>
								<div>{`${t('shuttle')} ${shuttleData.name}`}</div>
								<div>
									{hasWritePermission && (
										<IonButton
											fill="clear"
											onClick={() => handleDeleteShuttle(item.id)}
											className={styles.buttonDelete}
											slot="end"
										>
											<Icon className={`icon icon-delete icon24`} />
										</IonButton>
									)}
								</div>
							</div>
							<TextInput
								name={shuttleData.name}
								className={`ion-text-end`}
								wrapperClass={`${item.name === '' ? 'borderAlert' : ''}`}
								value={shuttleData.name}
								defaultValue={defaultName}
								onIonChange={(e) => {
									changeEditItem(item.id, 'name', e.detail.value);
								}}
								placeholder={t('example_shuttle')}
								disabled={!hasWritePermission}
								aria-label={t('name_shuttle')}
								label={t('name_shuttle')}
							/>
							<ReactQuill
								id="schedule"
								theme="snow"
								modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
								className={styles.content}
								value={shuttleData.schedule}
								onChange={(e) => {
									changeEditItem(item.id, 'schedule', e);
								}}
								onFocus={() => setChangeQuill(true)}
								readOnly={!hasWritePermission}
							/>
						</div>
					);
				}
			})}
			<div className={styles.separator}></div>
			{hasWritePermission && (
				<>
					<Button
						slot="start"
						className={`${styles.btnSave} ${styles.secondaryBtn}`}
						onClick={() => handleAddShuttle()}
					>
						<Icon className="icon icon-plus iconPlus" />
						<IonLabel>{t('add_shuttle')}</IonLabel>
					</Button>
					<div className={`${footerStyle} ${styles.footerButton}`}>
						<Button disabled={!isEdited} color="primary" className={styles.btnHeader} onClick={handleSaveData}>
							{t('btn_save_data')}
						</Button>
					</div>
				</>
			)}

			<Alert
				isOpen={showAlertDelete}
				onDidDismiss={() => setShowAlertDelete(false)}
				header={t('delete_shuttle')}
				message={t('confirm_delete_shuttle')}
				buttons={[
					{ text: 'No', role: 'cancel' },
					{ text: t('lbl_affirmative'), handler: () => deleteShuttle() },
				]}
				mode="ios"
			/>
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={10000} />
		</>
	);
};
