import { Button, Icon } from '@acciona/ui-ionic-kit';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export const Other: React.FC = () => {
	const { t } = useTranslation();

	const openNewTab = (link) => {
		window.open(link, '_blank');
	};

	const list = [];
	for (let $x = 1; $x <= 4; $x++) {
		list.push(
			<li key={$x} className={`${styles.footnote} ${styles.listText}`}>
				{t(`text_habitat${$x}`)}
			</li>,
		);
	}
	return (
		<>
			<div className={styles.containerOther}>
				<div>
					<div className={`${styles.h3} ${styles.space}`}>{t('label_config_habitat')}</div>
					<ul>
						{list.map((l) => {
							return l;
						})}
					</ul>
				</div>
				<div>
					<Button
						className={styles.secondaryBtn}
						onClick={() => openNewTab(process.env.REACT_APP_LINK_HABITAT)}
						slot="end"
					>
						<Icon className="icon  icon-extern iconPlus" /> {t('goto_habitat')}
					</Button>
				</div>
			</div>
			<div className={styles.separator} />
			<div className={styles.containerOther}>
				<div>
					<div className={`${styles.h3} ${styles.space}`}>{t('label_config_togo')}</div>
					<ul>
						<li className={`${styles.footnote} ${styles.listText}`}>{t(`text_togo_room`)}</li>
					</ul>
				</div>
				<div>
					<Button
						className={styles.secondaryBtn}
						onClick={() => openNewTab(process.env.REACT_APP_LINK_TOGO)}
						slot="end"
					>
						<Icon className="icon  icon-extern iconPlus" /> {t('goto_togo')}
					</Button>
				</div>
			</div>
		</>
	);
};
