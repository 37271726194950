import { useCallback } from 'react';
import { IonCheckbox } from '@ionic/react';
import { Item } from '@acciona/ui-ionic-kit';
import { List } from 'react-virtualized';
import { IEmployeeResult } from './types';
import styles from './styles.module.scss';

type Props = {
	usersList: IEmployeeResult[];
	checkUser: (detailChecked: boolean, id: number) => void;
	hasWritePermission?: boolean;
};

export const VirtualizedList: React.FC<Props> = (props) => {
	const { usersList, checkUser, hasWritePermission } = props;

	const listHeight = 248;
	const rowHeight = 44;
	const rowWidth = 752;

	const renderRow = useCallback(
		({ index, style }) => {
			const user = usersList[index];

			return (
				<Item key={user.employeeId} mode="ios" lines="none" type="button" style={style}>
					<IonCheckbox
						color="primary"
						checked={user.isChecked}
						onIonChange={(e) => checkUser(e.detail.checked, user.employeeId)}
						labelPlacement="end"
						justify="start"
						disabled={!hasWritePermission}
					>
						{`${user.employeeId} - ${user.fullName}`}
					</IonCheckbox>
				</Item>
			);
		},
		[usersList, checkUser],
	);

	return (
		<>
			<List
				width={rowWidth}
				height={listHeight}
				rowHeight={rowHeight}
				rowRenderer={renderRow}
				rowCount={usersList.length}
				overscanRowCount={3}
				className={styles.virtualizedList}
			/>
		</>
	);
};
