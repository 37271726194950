import { useEffect, useMemo, useState } from 'react';
import { Button, Icon, Item, Modal, PickerCalendar } from '@acciona/ui-ionic-kit';
import { IonContent, IonFooter, IonLabel, IonRadio, IonRadioGroup, IonTitle, IonToolbar } from '@ionic/react';
import { ModalEditPointsProps as Props } from '../../types';
import { useTranslation } from 'react-i18next';
import { RangeCalendar } from '../../../../../components/RangeCalendar';
import {
	initialFormData,
	getUserText,
	getMultipleUsersText,
	getUpdatedData,
	dateOptions,
	checkSaveIsEnabled,
} from './helpers';
import styles from './styles.module.scss';
import { startOfDay } from '../../../../../utils/functions';
import { NumberInput } from '../../../../../components/NumberInput/NumberInput';

export const ModalEditPoints: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { selectedRows: originalData } = props;
	const [formData, setFormData] = useState(initialFormData);
	const [selectedDateOption, setSelectedDateOption] = useState('0');
	const saveIsEnabled = useMemo(
		() => checkSaveIsEnabled(originalData, formData, selectedDateOption),
		[originalData, formData, selectedDateOption],
	);

	useEffect(() => {
		let isMounted = true;
		if (isMounted) {
			if (originalData.length === 1) {
				setFormData({
					manualPoints: originalData[0]?.manualPoints ?? 0,
					startDate: originalData[0]?.startDate ? startOfDay(new Date(originalData[0].startDate)) : null,
					endDate: originalData[0]?.endDate ? startOfDay(new Date(originalData[0].endDate)) : null,
					textNames: (
						<>
							{t('manual_points_asignation_individual_user')}
							<span>{getUserText(originalData[0])}</span>
						</>
					),
				});

				const selectedOption =
					originalData[0]?.startDate && originalData[0]?.endDate ? '2' : originalData[0]?.startDate ? '1' : '0';
				setSelectedDateOption(selectedOption);
			} else if (originalData.length > 1) {
				setFormData({
					manualPoints: 0,
					startDate: null,
					endDate: null,
					textNames: (
						<>
							{t('manual_points_asignation_multiple_users', { number: originalData.length })}
							<br />
							<span>{getMultipleUsersText(originalData)}</span>
							{originalData.length > 3 && <span className={styles.chipMore}>+ {originalData.length - 3}</span>}
						</>
					),
				});

				setSelectedDateOption('0');
			}
		}

		return () => {
			isMounted = false;
		};
	}, [originalData]);

	const closeModal = () => {
		props.onClose();
	};

	const handleCancel = () => {
		props.onClose();
	};

	const handleSaveChanges = () => {
		const updatedData = getUpdatedData(originalData, formData, selectedDateOption);
		props.updateTableData(updatedData);
		closeModal();
	};

	const onChangePoints = (e) => {
		setFormData((prev) => ({
			...prev,
			manualPoints: e.target.value,
		}));
	};

	const onChangeOption = (e) => {
		setSelectedDateOption(e.detail.value);
	};

	const handleSelectStartDate = (date) => {
		setFormData((prev) => ({
			...prev,
			startDate: startOfDay(date),
		}));
	};

	const handleSelectDateRange = (dates) => {
		const { startDate, endDate } = dates;
		setFormData((prev) => ({
			...prev,
			startDate: startDate,
			endDate: endDate,
		}));
	};

	return (
		<Modal isOpen={props.showModal} onDidDismiss={closeModal} className={styles.modal}>
			<IonToolbar className={styles.toolbar}>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('lbl_aria_close_modal')}
					onClick={closeModal}
				>
					<Icon className="icon icon-close" aria-hidden="true"></Icon>
				</Button>
				<IonTitle className={`${styles.centerTitle} ${styles.headline}`}>{t('lbl_modal_edit_points')}</IonTitle>
			</IonToolbar>
			<IonContent>
				<div>
					<div className={`${styles.footnote} ${styles.textUsers}`}>{formData.textNames}</div>
					<NumberInput
						name="manualPoints"
						className={`ion-text-end`}
						value={formData.manualPoints}
						onIonChange={onChangePoints}
						disabled={false}
						required
						aria-label={t('manual_points')}
						label={t('manual_points')}
					/>
					<p className={`${styles.footnote} ${styles.plannedDateText}`}>{t('txt_points_implementation')}</p>
					<IonRadioGroup className={styles.plannedDate} value={selectedDateOption} onIonChange={onChangeOption}>
						{Object.entries(dateOptions).map(([key, value]) => (
							<Item key={key} lines="none" mode="md" type="button">
								<IonRadio mode="md" color="primary" slot="start" value={key} aria-label={value} />
								<IonLabel>{value}</IonLabel>
							</Item>
						))}
					</IonRadioGroup>
					{selectedDateOption === '1' && (
						<div className={styles.calendarContainer}>
							<PickerCalendar
								minimumDate={null}
								selectedDate={handleSelectStartDate}
								date={formData.startDate}
								locale={props.userLanguage}
							/>
						</div>
					)}
					{selectedDateOption === '2' && (
						<div className={styles.calendarContainer}>
							<RangeCalendar
								startDate={formData.startDate}
								endDate={formData.endDate}
								onSelectDates={handleSelectDateRange}
							/>
						</div>
					)}
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button color="lighter" className={styles.btnHeader} onClick={handleCancel}>
						{t('btn_cancel')}
					</Button>
				</div>

				{props.hasWritePermission && (
					<div>
						<Button
							color="primary"
							className={styles.btnHeader}
							disabled={!saveIsEnabled}
							onClick={handleSaveChanges}
						>
							{t('btn_save')}
						</Button>
					</div>
				)}
			</IonFooter>
		</Modal>
	);
};
