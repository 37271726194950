import { useEffect, useState } from 'react';
import { IonButton, IonLabel, IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Icon } from '@acciona/ui-ionic-kit';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import ReactQuill from 'react-quill';
import _ from 'lodash';
import { Restaurant, RestaurantData } from '../../../../_api/services/generalServices/types';
import { generalServicesService } from '../../../../_api/services/generalServices';
import useAppContext from '../../../../hooks/useAppContext';
import { LanguageSelector } from '../../../../components/LanguageSelector/LanguageSelector';
import { DEFAULT_REACT_QUILL_EDITOR_CONFIG } from '../../../../utils/constants';
import {
	fillEmptyTranslations,
	getDefaultName,
	getNewRestaurantData,
	getRestaurantsDataAfterDelete,
	getRestaurantsDataAfterEdit,
	getRestaurantsDataToSave,
	validateData,
} from './helpers';
import 'react-quill/dist/quill.snow.css';
import styles from './styles.module.scss';
import { TextInput } from '../../../../components/TextInput/TextInput';

export type Props = {
	hasWritePermission: boolean;
	setError: (errorMessage: string) => void;
	setSuccess: (errorMessage: string) => void;
	footerStyle: string;
};

export const RestaurantsTab: React.FC<Props> = (props) => {
	const { hasWritePermission, setError, setSuccess, footerStyle } = props;
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const [selectedLang, setSelectedLang] = useState('es');
	const [idRestaurantToDelete, setIdRestaurantToDelete] = useState(0);
	const [showAlertDelete, setShowAlertDelete] = useState(false);
	const [changeQuill, setChangeQuill] = useState(false);
	const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
	const [isEdited, setIsEdited] = useState(false);

	const { data: initialData, isLoading: isLoading } = useQuery<Restaurant[]>(
		['restaurants'],
		async () => await generalServicesService.getRestaurants(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setRestaurants(
					data.map((d) => {
						return { ...d, new: false, edited: false };
					}),
				);
			},
		},
	);
	const { mutate: handleSave, isLoading: loadingSave } = useMutation(generalServicesService.saveRestaurants, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			setIsEdited(false);
			queryClient.refetchQueries('restaurants');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	useEffect(() => {
		initialData && setRestaurants(initialData.map((item) => ({ ...item, new: false, edited: false })));
	}, [initialData]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleDeleteRestaurant = (id: number) => {
		setIdRestaurantToDelete(id);
		setShowAlertDelete(true);
	};

	const deleteRestaurant = () => {
		setRestaurants(getRestaurantsDataAfterDelete(restaurants, initialData, idRestaurantToDelete));
		setIsEdited(true);
	};

	const changeEditItem = (id: number, item: string, newValue: string) => {
		const restaurantEdited = restaurants.find((restaurant) => restaurant.id === id);
		const currentValue = restaurantEdited.translations.find((e) => e.language == selectedLang)[item];

		if (currentValue !== newValue) {
			setIsEdited(item !== 'details' || changeQuill);
			const newRestaurantTranslations = getRestaurantsDataAfterEdit(restaurants, id, item, newValue, selectedLang);
			setRestaurants(newRestaurantTranslations);
		}
	};

	const handleAddRestaurant = () => {
		if (validateData(restaurants)) {
			setRestaurants((prevRestaurants) => [...prevRestaurants, getNewRestaurantData(restaurants)]);
		} else {
			setError(t('msg_error_all_fields_required'));
		}
	};

	const handleSaveData = () => {
		if (validateData(restaurants)) {
			handleSave(getRestaurantsDataToSave(restaurants));
		} else {
			setError(t('msg_error_all_fields_required'));
		}
	};

	useEffect(() => {
		if (selectedLang) {
			setRestaurants((prev) =>
				prev.map((restaurant) => {
					return {
						...restaurant,
						translations: fillEmptyTranslations(restaurant.translations),
					};
				}),
			);
		}
	}, [selectedLang]);

	return (
		<div key={`RestaurantsTab-${selectedLang}`}>
			<LanguageSelector selectedLang={selectedLang} setSelectedLang={setSelectedLang} />
			<p className={styles.footnote}>{t('restaurant_text_header')}</p>
			{_.sortBy(
				restaurants?.filter((s) => s.enabled),
				'id',
			).map((item: Restaurant) => {
				const restaurantData: RestaurantData = item?.translations?.find((e) => e.language == selectedLang) ?? null;
				if (restaurantData) {
					const defaultName = getDefaultName(initialData, item, selectedLang);
					return (
						<div key={item.id}>
							<div className={styles.h2}>
								<div>{`${t('restaurant')} ${restaurantData.name}`}</div>
								<div>
									{hasWritePermission && (
										<IonButton
											fill="clear"
											onClick={() => handleDeleteRestaurant(item.id)}
											className={styles.buttonDelete}
											slot="end"
										>
											<Icon className={`icon icon-delete icon24`} />
										</IonButton>
									)}
								</div>
							</div>
							<TextInput
								name={restaurantData.name}
								className={`ion-text-end`}
								wrapperClass={`${restaurantData.name === '' ? 'borderAlert' : ''}`}
								value={restaurantData.name}
								defaultValue={defaultName}
								onIonChange={(e) => {
									changeEditItem(item.id, 'name', e.detail.value);
								}}
								placeholder={t('example_restaurant')}
								disabled={!hasWritePermission}
								aria-label={t('name_restaurant')}
								label={t('name_restaurant')}
							/>

							<ReactQuill
								id="restaurant"
								theme="snow"
								modules={DEFAULT_REACT_QUILL_EDITOR_CONFIG}
								className={styles.content}
								value={restaurantData.details}
								onChange={(e) => {
									changeEditItem(item.id, 'details', e);
								}}
								onFocus={() => setChangeQuill(true)}
								readOnly={!hasWritePermission}
							/>
						</div>
					);
				}
			})}
			<div className={styles.separator}></div>
			{hasWritePermission && (
				<>
					<Button
						slot="start"
						className={`${styles.btnSave} ${styles.secondaryBtn}`}
						onClick={handleAddRestaurant}
					>
						<Icon className="icon icon-plus iconPlus" />
						<IonLabel>{t('add_restaurant')}</IonLabel>
					</Button>
					<div className={`${footerStyle} ${styles.footerButton}`}>
						<Button disabled={!isEdited} color="primary" className={styles.btnHeader} onClick={handleSaveData}>
							{t('btn_save_data')}
						</Button>
					</div>
				</>
			)}

			<Alert
				isOpen={showAlertDelete}
				onDidDismiss={() => setShowAlertDelete(false)}
				header={t('delete_restaurant')}
				message={t('confirm_delete_restaurant')}
				buttons={[
					{ text: 'No', role: 'cancel' },
					{ text: t('lbl_affirmative'), handler: () => deleteRestaurant() },
				]}
				mode="ios"
			/>
			<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={10000} />
		</div>
	);
};
