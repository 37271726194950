import { useEffect, useState } from 'react';
import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import {
	IonContent,
	IonFooter,
	IonItem,
	IonLabel,
	IonList,
	IonRadio,
	IonRadioGroup,
	IonTitle,
	IonToolbar,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { CustomSearchBar } from '../CustomSearchBar/CustomSearchBar';
import { Props } from './types';
import { ConfigurationPreference, Sede } from '../../_api/services/user/types';
import { getPreferencesOptions, sortSedesList } from '../../utils/functions';
import useAppContext from '../../hooks/useAppContext';
import { CONFIGURATION_PREFERENCES } from '../../utils/constants';
import styles from './styles.module.scss';

const filterCoincidentItems = (items: Sede[], query: string) => {
	if (query === '') {
		return items;
	} else {
		return items.filter((item) => item.description.toLowerCase().includes(query.toLowerCase()));
	}
};

const ModalHeadOfficeSelector: React.FC<Props> = (props) => {
	const { configurationPreference, transversal, sedePortalOptions } = useAppContext();
	const { t } = useTranslation();
	const [selectedSede, setSelectedSede] = useState<Sede>(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [results, setResults] = useState<Sede[]>([]);
	const [tempSelectedSede, setTempSelectedSede] = useState<Sede>(null);
	const [configurationOption, setConfigurationOption] = useState<ConfigurationPreference>(
		configurationPreference ?? CONFIGURATION_PREFERENCES.sede.id,
	);
	const canClose = props?.canClose !== false;

	useEffect(() => {
		setResults(props.data);
	}, [props.data]);

	useEffect(() => {
		setSelectedSede(props.selectedItem);
		setTempSelectedSede(props.selectedItem);
	}, [props.selectedItem]);

	const handleSearchChange = (query: string) => {
		setSearchQuery(query);
		setResults(filterCoincidentItems(props.data, query));
	};

	const handleSaveChanges = () => {
		setSearchQuery('');
		props.onSave(tempSelectedSede, configurationOption);
		props.onClose();
	};

	const handleCloseModal = () => {
		setSelectedSede(props.selectedItem);
		setTempSelectedSede(props.selectedItem);
		props.onClose();
	};

	const sortedResults = sortSedesList(results, selectedSede, 'description');

	const canSave = configurationOption === CONFIGURATION_PREFERENCES.transversal.id || tempSelectedSede;

	const preferencesOptions = getPreferencesOptions(transversal, sedePortalOptions);

	if (_.isEmpty(preferencesOptions)) {
		return <></>;
	}

	return (
		<Modal
			{...props}
			isOpen={props.showModal}
			onDidDismiss={() => {
				props.onClose();
			}}
			className={`${styles.modalHeadOffice} ${props.backdropOpaque ? styles.backdropOpaque : ''} ${
				configurationOption === CONFIGURATION_PREFERENCES.transversal.id ? styles.transversal : styles.sede
			}`}
			backdropDismiss={canClose}
		>
			<IonToolbar className={styles.title}>
				{canClose && (
					<Button slot="end" fill={'clear'} color="light" className={styles.closeBtn} onClick={handleCloseModal}>
						<IonLabel className="dark">
							<Icon color="primary" className="icon icon-close icon20"></Icon>
						</IonLabel>
					</Button>
				)}

				<IonTitle className={`${styles.centerTitle} ${styles.headline}`}>
					{preferencesOptions.length === 1 ? t('title_sede_selector') : t('title_configuration_selector')}
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<div className={styles.contentModal}>
					{preferencesOptions.length === 1 ? (
						<></>
					) : (
						<IonRadioGroup
							className={styles.configurationOptions}
							value={configurationOption}
							onIonChange={(e) => {
								setConfigurationOption(e.target.value);
							}}
						>
							{preferencesOptions.map(({ id, description }) => (
								<IonItem key={id} lines="none">
									<IonLabel>{description}</IonLabel>
									<IonRadio slot="start" mode="md" value={id} aria-label={description} />
								</IonItem>
							))}
						</IonRadioGroup>
					)}
					{configurationOption === CONFIGURATION_PREFERENCES.transversal.id ? (
						<></>
					) : (
						<>
							<CustomSearchBar
								className={styles.searchBar}
								value={searchQuery}
								onIonInput={(e) => handleSearchChange(e?.target?.value ?? '')}
								onIonClear={() => setSearchQuery('')}
								placeholder={t('placeholder_sede_selector')}
								debounce={500}
								clearIcon={undefined}
							/>

							<IonList className={styles.list}>
								<IonRadioGroup
									value={tempSelectedSede}
									onIonChange={(e) => setTempSelectedSede(e.detail.value)}
								>
									{!_.isEmpty(sortedResults) &&
										sortedResults?.map((item) => {
											const isSelected = item.id === tempSelectedSede?.id;
											return (
												<IonItem key={item.id} className={styles.selector} lines="none">
													<IonRadio value={item} labelPlacement="start">
														{item.description ?? ''}
													</IonRadio>
													{isSelected && (
														<Icon className={`icon icon-check icon24`} color="primary" slot="end"></Icon>
													)}
												</IonItem>
											);
										})}
								</IonRadioGroup>
							</IonList>
						</>
					)}
				</div>
			</IonContent>

			<IonFooter className={styles.footer}>
				<div className={styles.buttons}>
					{canClose && (
						<Button className={`${styles.secondaryBtn} ${styles.btnFooterAction}`} onClick={handleCloseModal}>
							{t('btn_cancel')}
						</Button>
					)}

					<Button
						color="primary"
						className={styles.btnFooterAction}
						onClick={handleSaveChanges}
						disabled={!canSave}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};

export default ModalHeadOfficeSelector;
