import { useEffect, useMemo, useState } from 'react';
import { IonItem, IonLabel, IonRadioGroup, IonRadio } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { ReservationPolicy } from '../../_api/services/workstation/types';
import { HourSelector } from '../HourSelector/HourSelector';
import { validateHours } from '../../utils/reservations';
import styles from './styles.module.scss';

type Props = {
	handleUpdateDefaultPolicy: (id: number) => void;
	handleUpdateMinHour: (id: number, hour: string) => void;
	handleUpdateMaxHour: (id: number, hour: string) => void;
	availableOptions: ReservationPolicy[];
	selectedOption: ReservationPolicy;
	hasWritePermission: boolean;
	sedePolicies: Record<string, number>;
};

export const DefaultPreferencesConfiguration = (props: Props) => {
	const {
		handleUpdateDefaultPolicy,
		handleUpdateMinHour,
		handleUpdateMaxHour,
		availableOptions,
		selectedOption,
		hasWritePermission,
		sedePolicies,
	} = props;
	const { t } = useTranslation();

	const [selectedOptionId, setSelectedOptionId] = useState(0);

	const hoursAreValid = useMemo(
		() =>
			selectedOption?.id !== sedePolicies.personalized ||
			validateHours(selectedOption?.fullHoraMin, selectedOption?.fullHoraMax),
		[selectedOption?.id, selectedOption?.fullHoraMin, selectedOption?.fullHoraMax],
	);

	const changePreference = (id: number) => {
		setSelectedOptionId(id);
		handleUpdateDefaultPolicy(id);
	};

	useEffect(() => {
		setSelectedOptionId(selectedOption?.id);
	}, [selectedOption?.id]);

	return (
		<>
			{!_.isEmpty(availableOptions) && (
				<>
					<div className={styles.element}>
						<div className={styles.blockSubtitle}>{t('lbl_timeAndReservations_default_user_preference')}</div>
						<IonRadioGroup
							className={styles.defautPreferencesOptions}
							value={selectedOptionId}
							onIonChange={(e) => changePreference(e.detail.value)}
						>
							{!_.isEmpty(availableOptions) &&
								availableOptions.map((option) => {
									return (
										<IonItem key={option.id} className={styles.selector} lines="none">
											<IonLabel>{t(`lbl_reservations_default_sede_policy_options.${option.id}`)}</IonLabel>
											<IonRadio slot="start" mode="md" value={option.id} aria-label={option.name} />
										</IonItem>
									);
								})}
						</IonRadioGroup>
					</div>

					<div className={styles.element}>
						<div className={styles.blockSubtitle}>
							{t('lbl_timeAndReservations_default_min_and_max_reservation_hour')}
						</div>
						<div className={styles.multipleInputsContainer}>
							<HourSelector
								name="minDefaultHour"
								label={t('lbl_start_hour')}
								value={selectedOption?.fullHoraMin}
								disabled={!hasWritePermission || selectedOptionId !== sedePolicies.personalized}
								isValid={hoursAreValid}
								onChange={(e) => handleUpdateMinHour(selectedOptionId, e.target.value)}
							/>
							<HourSelector
								name="maxDefaultHour"
								label={t('lbl_end_hour')}
								value={selectedOption?.fullHoraMax}
								disabled={!hasWritePermission || selectedOptionId !== sedePolicies.personalized}
								isValid={hoursAreValid}
								onChange={(e) => handleUpdateMaxHour(selectedOptionId, e.target.value)}
							/>
						</div>
					</div>
				</>
			)}
		</>
	);
};
