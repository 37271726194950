import { useLayoutEffect, useRef, useState } from 'react';
import { flexRender } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import { TableFooter } from '../TableFooter';
import { SkeletonTable } from '../SkeletonTable';
import styles from './styles.module.scss';
import { CustomSearchBar } from '../../../../../components/CustomSearchBar/CustomSearchBar';

type Props = {
	isFetching: boolean;
	table: any;
	onEdit: (any) => void;
	selectedRows: any[];
	totalRows: number;
	search: string;
	setSearch: (any) => void;
	searchPlaceholder: string;
	totalSelectedRows: number;
	pageSize?: number;
	hasWritePermission: boolean;
	maxHeight?: string;
};

export const TablePaginated: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const {
		isFetching,
		table,
		onEdit,
		selectedRows,
		totalRows,
		totalSelectedRows,
		setSearch,
		searchPlaceholder,
		pageSize,
	} = props;
	const [placeholder, setPlaceholder] = useState(searchPlaceholder);
	const tableContainerRef = useRef(null);
	useLayoutEffect(() => {
		if (tableContainerRef?.current && props.maxHeight) {
			tableContainerRef.current.style.setProperty('--container-max-height', props.maxHeight);
		}
	}, [props.maxHeight]);

	const cancelSearchHandler = () => {
		setSearch('');
		setPlaceholder(searchPlaceholder);
	};

	const handleEdit = () => {
		onEdit(selectedRows);
	};

	return (
		<div className={styles.container}>
			<div className={styles.topBarContainer}>
				<div className={styles.searchContainer}>
					<CustomSearchBar
						onIonInput={(e) => setSearch(e.detail.value)}
						onIonClear={cancelSearchHandler}
						placeholder={placeholder}
						onIonFocus={() => setPlaceholder('')}
						debounce={1000}
					/>
				</div>
				<div className={styles.selectedInfo}>
					<div>{`${t('table_total_registers')}: ${totalRows ?? 0}`}</div>
					<div>{`${t('table_selected_registers')}: ${totalSelectedRows}`}</div>
					<div>
						{props.hasWritePermission && (
							<button hidden={totalSelectedRows === 0} className={styles.editButton} onClick={handleEdit}>
								<Icon className="icon icon-edit" />
								<span>{t('table_edit')}</span>
							</button>
						)}
					</div>
				</div>
			</div>
			<div ref={tableContainerRef} className={styles.tableContainer}>
				<table className={styles.table}>
					<thead>
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<th key={header.id} colSpan={header.colSpan} style={{ width: header.getSize() }}>
											{header.isPlaceholder ? null : (
												<div
													onClick={header.column.getToggleSortingHandler()}
													className={header.column.getIsSorted() ? '' : styles.notSortable}
												>
													{flexRender(header.column.columnDef.header, header.getContext())}
													{{
														asc: ' ↑',
														desc: ' ↓',
													}[header.column.getIsSorted() as string] ?? null}
												</div>
											)}
										</th>
									);
								})}
							</tr>
						))}
					</thead>
					<tbody>
						{isFetching ? (
							<SkeletonTable pageSize={pageSize} />
						) : (
							table.getRowModel().rows.map((row) => {
								return (
									<tr key={row.id}>
										{row.getVisibleCells().map((cell) => {
											return (
												<td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
											);
										})}
									</tr>
								);
							})
						)}
					</tbody>
				</table>
			</div>
			<TableFooter table={table} display={totalRows > 0} />
		</div>
	);
};
