import { Button, Icon, Modal, MultiSelect, Reorder, ReorderGroup } from '@acciona/ui-ionic-kit';
import styles from './styles.module.scss';
import { IonButton, IonContent, IonFooter, IonInput, IonItem, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Toggle } from '../../../../components/Toggle/Toggle';
import _ from 'lodash';
import { TextInput } from '../../../../components/TextInput/TextInput';
import { MenuConfig } from '../../../../_api/services/generalServices/types';
import { CustomModal as DeleteSectionModal } from '../../../../components/CustomModal/CustomModal';
import {
	allSectionHaveName,
	allSectionsHaveServices,
	checkChanges,
	generateNewSection,
	getServiceList,
	moveItemInArrayByKey,
} from './utils';

type Props = {
	showModal: boolean;
	onClose: () => void;
	lang: string;
	hasWritePermission: boolean;
	handleChangeToggle: any;
	sections: any;
	saveChanges: (state: MenuConfig) => void;
	setSuccess: any;
	ungroupedSectionId: any;
};

const GroupsModal: React.FC<Props> = ({
	showModal,
	onClose,
	lang,
	hasWritePermission,
	sections,
	saveChanges,
	setSuccess,
	ungroupedSectionId,
}) => {
	const { t } = useTranslation();
	const [sectionsMod, setSectionsMod] = useState(JSON.parse(JSON.stringify(sections)));
	const [newSectionName, setNewSectionName] = useState('');
	const [newSectionId, setNewSectionId] = useState(Math.min(...sections.map((c) => c.id)) - 1);
	const [sectionIdToDelete, setSectionIdToDelete] = useState<number>(-1);
	const [isReordering, setIsReordering] = useState(true);

	const [canSave, setCanSave] = useState(false);

	const [services, setServices] = useState([]);

	const [edit, setEdit] = useState(false);

	const [showDeleteSectionModal, setShowDeleteSectionModal] = useState(false);

	const handleGroupReorder = (e: any) => {
		e.detail.complete();

		const action = e.detail as any;
		setSectionsMod((oldState: MenuConfig) => {
			const newState = moveItemInArrayByKey(structuredClone(oldState), 'order', action.from, action.to);

			setCanSave(checkChanges(newState, sections, lang));
			return newState;
		});
	};

	const handleCreateNewSection = (name: string) => {
		setSectionsMod((oldState: MenuConfig) => {
			const newState = structuredClone(oldState);
			if (newState.find((g) => g.id === newSectionId)) {
				// TODO: same id found, raise error
				return oldState;
			}
			setNewSectionName('');
			const order = Math.max(...newState.map((g) => g.order)) + 1;
			const newSection = generateNewSection(newSectionId, order, name);
			newState.push(newSection);
			// update new section id
			setNewSectionId((prevState) => prevState - 1);
			return newState;
		});
	};

	const handleSelectServices = (itemComposedId: string, destinySectionId: number) => {
		const [originSectionId, itemId] = itemComposedId.split('-').map((v) => +v);
		// if user tries to unclick, the destiny section is ungrouped
		if (originSectionId === destinySectionId) {
			destinySectionId = ungroupedSectionId;
		}
		setSectionsMod((oldState: MenuConfig) => {
			const newState = structuredClone(oldState);
			const originSection = newState.find((s) => s.id === originSectionId);
			if (!originSection) return oldState;
			const itemIndex = originSection.items.findIndex((i) => i.id === itemId);
			if (!itemId) return oldState;
			// remove item from current section
			const item = originSection.items.splice(itemIndex, 1)[0];
			if (!item) return oldState;
			const destinySection = newState.find((s) => s.id === destinySectionId);
			if (!destinySection) return oldState;
			// reorder items in origin section
			originSection.items.forEach((item, index) => {
				item.order = index + 1;
			});

			// just in case reorder items in destiny section
			destinySection.items.forEach((item, index) => {
				item.order = index + 1;
			});
			// update order in new section and push
			item.order = destinySection.items.length + 1;
			destinySection.items.push(item);
			item.section = structuredClone(destinySection.translations);

			setCanSave(checkChanges(newState, sections, lang));
			return newState;
		});
	};

	const handleChangeSectionName = (value: string, sectionId: number): void => {
		setSectionsMod((oldState: MenuConfig) => {
			const newState = structuredClone(oldState);
			const section = newState.find((s) => s.id === sectionId);
			if (!section) return oldState;
			const translation = section.translations.find((t) => t.language === lang);
			if (!translation) return oldState;
			translation.text = value;
			section.items.forEach((item) => {
				item.section = structuredClone(section.translations);
			});
			setCanSave(checkChanges(newState, sections, lang));
			return newState;
		});
	};

	const handleDeleteSectionBtn = (sectionId: number) => {
		setSectionIdToDelete(sectionId);
		setShowDeleteSectionModal(true);
	};

	const handleDeleteSectionConfirm = () => {
		setSectionsMod((oldState) => {
			const newState = structuredClone(oldState);
			const sectionToDelete = newState.find((s) => s.id === sectionIdToDelete);
			const ungroupedSection = newState.find((section) => section.id === ungroupedSectionId);
			// move items to ungrouped section and update order
			ungroupedSection.items.push(...structuredClone(sectionToDelete.items));
			ungroupedSection.items.forEach((item, index) => {
				item.order = index + 1;
				item.section = structuredClone(ungroupedSection.translations);
			});
			// set section as deleted
			sectionToDelete.deleted = true;
			sectionToDelete.items = [];
			sectionToDelete.order = 1e6;

			// reorder sections
			newState
				.sort((a, b) => {
					return a.order - b.order;
				})
				.forEach((item, index) => {
					item.order = index + 1;
				});
			setCanSave(checkChanges(newState, sections, lang));
			return newState;
		});
		setShowDeleteSectionModal(false);
		setSuccess(t('msg_category_successfully_deleted'));
	};

	const handleSave = () => {
		saveChanges(sectionsMod);
		onClose();
	};

	useEffect(() => {
		setSectionsMod(JSON.parse(JSON.stringify(sections)));
	}, [sections]);

	useEffect(() => {
		setServices(() => {
			return getServiceList(sectionsMod, lang);
		});
	}, [sectionsMod]);

	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => {
				onClose();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<IonTitle>
					<h3 className="ion-text-center">
						<b>{`${t('edit_categories')} (${t('lang_label')}: ${t('language_' + lang)})`}</b>
					</h3>
				</IonTitle>
			</IonToolbar>

			<IonContent>
				<div>
					<IonItem className={styles.categoryListText} lines="none">
						<p>{t('category_modal_text')}</p>
					</IonItem>
					<div className="subHeader">
						<div className="title"></div>
						{hasWritePermission && (
							<>
								<div className="buttons">
									<Toggle
										disabled={!hasWritePermission}
										checked={edit}
										onChange={() => {
											setEdit((v) => !v);
										}}
									/>
								</div>
								<div className="label">
									<IonLabel>{t('lbl_edit')}</IonLabel>
								</div>
							</>
						)}
					</div>
					{edit ? (
						// MODIFY SECTION NAME AND DELETE
						<div className={styles.categoryNameChangeContainer}>
							{sectionsMod &&
								_.sortBy(sectionsMod, 'order')
									.filter((item) => !item.deleted)
									.map((item) => {
										let title = item?.translations.find((tr) => tr.language === lang).text;
										if (!title) {
											title = t('lbl_no_category_items', { lng: lang });
										}
										return (
											<IonItem lines="none" key={item.id} className={styles.inputSection}>
												<IonItem
													lines="none"
													className={`${styles.inputModal} ${
														item.id === ungroupedSectionId ? 'borderAlert' : ''
													}`}
												>
													<IonInput
														className={`ion-text-end`}
														value={title}
														onIonChange={(e) =>
															handleChangeSectionName(e.target.value as string, item.id)
														}
														placeholder={t('name')}
														aria-label={t('category')}
														label={t('category')}
														disabled={item.id === ungroupedSectionId}
													></IonInput>
												</IonItem>
												<IonButton
													fill="clear"
													onClick={() => handleDeleteSectionBtn(item.id)}
													className={styles.buttonDelete}
													slot="end"
													disabled={item.id === ungroupedSectionId}
												>
													<Icon className={`icon icon-delete icon24`} />
												</IonButton>
											</IonItem>
										);
									})}
						</div>
					) : (
						// REORDERING
						<ReorderGroup
							className="orderGroup"
							disabled={!hasWritePermission || !isReordering}
							onIonItemReorder={handleGroupReorder}
						>
							{_.sortBy(sectionsMod, 'order')
								.filter((item) => !item.deleted)
								.map((item) => {
									let title = item?.translations.find((tr) => tr.language === lang).text;
									if (!title) {
										title = t('lbl_no_category_items', { lng: lang });
									}
									// UNGROUPED SECTION IS NOT MULTISELECT
									if (item.id === ungroupedSectionId) {
										const servicesCount = item.items.length;
										const label = servicesCount === 1 ? 'lbl_service' : 'lbl_services';
										return (
											<Reorder key={item.id} className={styles.reorderItem}>
												<div className={`${styles.multiselectWrapper} ${styles.sectionUngrouped}`}>
													<div>{title}</div>
													<div className={styles.color_dark}>{`${servicesCount} ${t(
														label,
													).toLowerCase()}`}</div>
												</div>
												{hasWritePermission && <Icon className="icon icon-reordenar"></Icon>}
											</Reorder>
										);
									}
									return (
										<Reorder key={item.id} className={styles.reorderItem}>
											<div
												className={styles.multiselectWrapper}
												onMouseOver={() => {
													setIsReordering(false);
												}}
												onMouseLeave={() => {
													setIsReordering(true);
												}}
											>
												<MultiSelect
													label={title}
													options={services}
													selected={item.items.map((i) => {
														return `${item.id}-${i.id}`;
													})}
													updateSelection={(prevItemId: string) =>
														handleSelectServices(prevItemId, item.id)
													}
													placeholder={t('lbl_select_services')}
													showItemCount={true}
													itemCountText={{
														singular: t('lbl_selected_singular'),
														plural: t('lbl_selected'),
													}}
												/>
											</div>
											{hasWritePermission && <Icon className="icon icon-reordenar"></Icon>}
										</Reorder>
									);
								})}
						</ReorderGroup>
					)}
					{/* CREATE NEW SECTION */}
					{hasWritePermission && (
						<>
							<IonItem lines="none" className={styles.titleNewCategory}>
								<p className={styles.footnote}>{t('category_modal_text_create')}</p>
							</IonItem>
							<div className={styles.inputAddCategory}>
								<TextInput
									name="section"
									className="ion-text-end"
									onIonInput={(e: any) => {
										setNewSectionName(e.target.value);
									}}
									value={newSectionName}
									placeholder={t('name')}
									aria-label={t('category')}
									label={t('category')}
								></TextInput>
								<div>
									<Button
										disabled={newSectionName.trim() === ''}
										className={`${styles.btnAdd} ${styles.secondaryBtn}`}
										onClick={() => {
											handleCreateNewSection(newSectionName);
										}}
										slot="end"
									>
										<Icon className="icon icon-plus iconPlus" />
										<IonLabel>{t('btn_add')}</IonLabel>
									</Button>
								</div>
							</div>
						</>
					)}
				</div>
			</IonContent>

			<IonFooter className={styles.btnModal}>
				<div>
					<Button
						className={`${styles.btnHeader} ${styles.secondaryBtn}`}
						onClick={() => {
							onClose();
						}}
					>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						disabled={
							!canSave ||
							!allSectionsHaveServices(sectionsMod, ungroupedSectionId) ||
							!allSectionHaveName(sectionsMod, ungroupedSectionId)
						}
						onClick={handleSave}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
			<DeleteSectionModal
				showModal={showDeleteSectionModal}
				onConfirm={handleDeleteSectionConfirm}
				onClose={() => setShowDeleteSectionModal(false)}
				title={t('title_delete_category_modal')}
				description={t('desc_delete_category_modal')}
			/>
		</Modal>
	);
};

export default GroupsModal;
