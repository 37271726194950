import { useEffect, useMemo, useState } from 'react';
import { IonLoading } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon, List, Toast } from '@acciona/ui-ionic-kit';
import { workstationService } from '../../../_api/services/workstation';
import { ErrorManagementConfig } from '../../../_api/services/workstation/types';
import useAppContext from '../../../hooks/useAppContext';
import styles from './styles.module.scss';
import { NumberInput } from '../../../components/NumberInput/NumberInput';
import UsersModal from '../../../components/UsersModal/UsersModal';
import { Employee } from '../../../_api/services/roles/types';
import { roleService } from '../../../_api/services/roles';
import { mockErrorManagementConfig } from '../../../_api/services/workstation/mockData';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

const hasEmptyValues = (formValues: any) =>
	Object.values(formValues).some((value) => value === null || value === undefined);

const defaultErrorManagementConfig = mockErrorManagementConfig;
export const ErrorManagement: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;
	const [errorManagementConfig, setErrorManagementConfig] =
		useState<ErrorManagementConfig>(defaultErrorManagementConfig);
	const [showEmailRecipientsModal, setShowEmailRecipientsModal] = useState(false);
	const [emailRecipients, setEmailRecipients] = useState<Employee[]>([]);
	const [toastMessage, setToastMessage] = useState(null);

	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();
	const { isLoading: isLoading, data: initialValues } = useQuery(
		['configErrorManagement'],
		async () => workstationService.getErrorManagementConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setErrorManagementConfig(data);
			},
		},
	);
	const { data: employees, isLoading: isLoadingEmployees } = useQuery(
		['employeesErrorManagement'],
		async () => await roleService.getPortalEmployees(),
		{
			refetchOnWindowFocus: false,
			onSuccess: (data: Employee[]) => {
				setEmailRecipients(
					data.map((employee) => {
						return {
							...employee,
							isChecked: errorManagementConfig?.usersToSendEmail?.includes(employee?.employeeId),
						};
					}),
				);
			},
		},
	);

	const { isLoading: loadingSave, mutate: handleSave } = useMutation(workstationService.updateErrorManagementConfig, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('configErrorManagement');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const isEdited = useMemo(() => {
		const isNotEqual = !_.isEqual(initialValues, errorManagementConfig);
		return isNotEqual;
	}, [initialValues, errorManagementConfig]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleOpenModalEmailRecipients = () => {
		setShowEmailRecipientsModal(true);
	};
	const checkedRecipients = useMemo(
		() => emailRecipients.filter((recipient) => recipient.isChecked),
		[emailRecipients, errorManagementConfig.usersToSendEmail],
	);
	const saveEmailRecipients = (nextRecipients: Employee[]) => {
		const nextRecipientsIds = nextRecipients.map((r) => r.employeeId);
		setEmailRecipients(
			employees.map((recipient) => ({
				...recipient,
				isChecked: nextRecipientsIds.includes(recipient.employeeId),
			})),
		);
		setErrorManagementConfig((prevConfig) => ({ ...prevConfig, usersToSendEmail: nextRecipientsIds }));
		setToastMessage(t('lbl_usersModal_accept'));
	};
	const handleInputChange = (e) => {
		if (_.isEqual(errorManagementConfig, defaultErrorManagementConfig)) {
			return;
		}
		setErrorManagementConfig((prevValues) => ({
			...prevValues,
			[e.target.name]: +e.target.value,
		}));
	};

	const handleOnSave = () => {
		const updatedData = _.cloneDeep(errorManagementConfig);
		if (hasEmptyValues(updatedData)) {
			setError(t('msg_error_all_fields_required'));
		} else {
			handleSave(updatedData);
		}
	};
	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<List>
						<header className={`${styles.h2} ${styles.headerSpace}`}>
							{t('title_errorManagement_AccessControl')}
						</header>
						{/* RESPONSE TIME */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_errorManagement_responseTime_description')}
							</div>
							<NumberInput
								name="responseTime"
								className={`ion-text-end`}
								value={errorManagementConfig.responseTime}
								onIonChange={handleInputChange}
								min={1}
								disabled={!hasWritePermission}
								aria-label={t('lbl_errorManagement_responseTime')}
								label={t('lbl_errorManagement_responseTime')}
								required
							></NumberInput>
						</div>
						{/* NUMBER OF FAILURES */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_errorManagement_numberOfFailures_description')}
							</div>
							<NumberInput
								name="numberOfFailures"
								className={`ion-text-end`}
								value={errorManagementConfig.numberOfFailures}
								onIonChange={handleInputChange}
								min={1}
								disabled={!hasWritePermission}
								aria-label={t('lbl_errorManagement_numberOfFailures')}
								label={t('lbl_errorManagement_numberOfFailures')}
								required
							></NumberInput>
						</div>
						{/* TIME INTERVAL */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_errorManagement_timeInterval_description')}
							</div>
							<NumberInput
								name="timeInterval"
								className={`ion-text-end`}
								value={errorManagementConfig.timeInterval}
								onIonChange={handleInputChange}
								min={1}
								disabled={!hasWritePermission}
								aria-label={t('lbl_errorManagement_timeInterval')}
								label={t('lbl_errorManagement_timeInterval')}
								required
							></NumberInput>
						</div>
						{/* USERS EMAIL RECIPIENTS*/}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_errorManagement_usersToSendEmail_description')}
								<div className={styles.input} onClick={handleOpenModalEmailRecipients}>
									<div>{t('lbl_users')}</div>
									<div className={`${styles.align}`}>
										<div className={`${checkedRecipients.length < 1 && styles.color_dark}`}>
											{checkedRecipients.length > 0
												? t('lbl_total_select', {
														total: checkedRecipients.length,
												  })
												: t('lbl_select')}
										</div>
										<Icon className="icon icon-chevron-right icon24" slot="end" />
									</div>
								</div>
							</div>
						</div>
					</List>
					<UsersModal
						onClose={() => setShowEmailRecipientsModal(false)}
						onSave={saveEmailRecipients}
						showModal={showEmailRecipientsModal}
						usersData={emailRecipients}
						label={t('lbl_errorManagement_usersToSendEmail_modal')}
						isLoading={isLoadingEmployees}
						hasWritePermission={hasWritePermission}
					/>
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button onClick={handleOnSave} className={styles.btnHeader} disabled={!isEdited} color="primary">
								{t('btn_save_data')}
							</Button>
						</div>
					)}

					<Toast
						isOpen={!!toastMessage}
						message={toastMessage}
						onDidDismiss={() => setToastMessage(null)}
						position="bottom"
						type="success"
					/>
				</>
			)}
		</>
	);
};
