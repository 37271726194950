import { MenuConfig, MenuSection } from '../../../../_api/services/generalServices/types';
import { arraymove } from '../../../../utils/functions';
import _ from 'lodash';

export const checkChanges = (newState: MenuConfig, original: MenuConfig, lang: string) => {
	return original.some((section) => {
		// find new section to check
		const referenceSection = newState.find((s) => s.id === section.id);

		// check same order
		if (!referenceSection || referenceSection.order !== section.order) {
			return true;
		}

		// check if deleted
		if (section.deleted !== referenceSection.deleted) {
			return true;
		}

		// check if new
		if (section.created !== referenceSection.created) {
			return true;
		}
		const sectionTranslation = section.translations.find((t) => t.language === lang);
		const referenceSectionTranslation = referenceSection.translations.find((t) => t.language === lang);

		// check if text has changed
		if (sectionTranslation?.text !== referenceSectionTranslation?.text) {
			return true;
		}

		// check if contains same items
		return section.items.some((item) => {
			if (!referenceSection) return true;
			const referenceItem = referenceSection.items.find((i) => i.id === item.id);
			if (!referenceItem) return true;
			return referenceItem.id !== item.id;
		});
	});
};

export const allSectionsHaveServices = (config: MenuConfig, ungroupedSectionId: number) => {
	return config.filter((s) => !s.deleted && s.id !== ungroupedSectionId).every((s) => s.items.length > 0);
};

export const getServiceList = (config: MenuConfig, lang: string) => {
	return config
		.reduce((acc, section) => {
			return [
				...acc,
				...section.items.map((item) => {
					return {
						label: item.translations.find((t) => t.language === lang).text,
						value: `${section.id}-${item.id}`,
					};
				}),
			];
		}, [])
		.sort((a, b) => a.label.localeCompare(b.label));
};

export const generateNewSection = (id: number, order: number, text: string): MenuSection => {
	return {
		id: id,
		order: order,
		translations: [
			{
				language: 'es',
				text,
			},
			{
				language: 'en',
				text,
			},
			{
				language: 'pt',
				text,
			},
		],
		items: [],
		created: true,
	};
};

export const moveItemInArrayByKey = (array: any, key: string, from: number, to: number) => {
	return arraymove(_.sortBy(array, key), from, to).map((e: any, index) => {
		return {
			...e,
			order: index + 1,
		};
	});
};

export const setModificationFlag = (section: MenuSection, originalData) => {
	if (section.deleted || section.created) return section;
	const prevSection = originalData.find((s) => s.id === section.id);
	const isTranslationsEqual = _.isEqual(prevSection.translations, section.translations);
	const isOrderEqual = prevSection.order === section.order;
	const isItemsEqual = _.isEqual(prevSection.items, section.items);
	if (!isTranslationsEqual || !isItemsEqual || !isOrderEqual) {
		section.modified = true;
	}
	return section;
};

export const allSectionHaveName = (data: MenuConfig, ungroupedSectionId: number) => {
	return data
		.filter((s) => s.id !== ungroupedSectionId)
		.every((s) => {
			return s.translations.every((t) => {
				return t.text?.trim() !== '';
			});
		});
};
