import { PkNotificationsConfig } from './types';

export const defaultNotificationsConfig: PkNotificationsConfig = {
	sendNotificationEnabled: true,
	sendEmailEnabled: false,
	minutesBeforeEndNotification: '15',
	minutesBeforeEndEmail: '30',
	doNotSendAfter: '22:00',
	emailTranslations: [
		{ language: 'es', subject: '', body: '' },
		{ language: 'en', subject: '', body: '' },
		{ language: 'pt', subject: '', body: '' },
	],
};
export const queryNotificationsConfig: PkNotificationsConfig = {
	sendNotificationEnabled: false,
	sendEmailEnabled: false,
	minutesBeforeEndNotification: '10',
	minutesBeforeEndEmail: '15',
	doNotSendAfter: '23:00',
	emailTranslations: [
		{ language: 'es', subject: '', body: '<p>Body de prueba</p>' },
		{ language: 'en', subject: '', body: '' },
		{ language: 'pt', subject: '', body: '' },
	],
};

export const MINUTES_OPTIONS = ['0', '5', '10', '15', '20', '25', '30'];
