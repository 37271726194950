import { useRef, useEffect, useState } from 'react';
import { IonContent, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { SegmentButton, Segmented } from '@acciona/ui-ionic-kit';
import useAppContext from '../../hooks/useAppContext';
import { useScrollBar } from '../../hooks/useScrollBar';
import { usePermissions } from '../../hooks/usePermissions';
import { Lockers } from './Lockers/Lockers';
import { Availability } from './Availability/Availability';
import { TimeAndReservations } from './TimeAndReservations/TimeAndReservations';
import { OtherConfigurations } from './OtherConfigurations/OtherConfigurations';
import { WsPermissions } from './WsPermissions/WsPermissions';
import { HeaderPage } from '../../components/HeaderPage/HeaderPage';
import { Feedback } from '../../components/Feedback/Feedback';
import { FUNCTIONALITIES, LOCKERS_MODE } from './../../utils/constants';
import styles from './styles.module.scss';
import Legends from './Legends/Legends';
import { ErrorManagement } from './ErrorManagement/ErrorManagement';

const Workstations: React.FC = () => {
	const { t } = useTranslation();
	const { sedePortal, setThereAreUnsavedChanges } = useAppContext();
	const [segmentedSelected, setSegmentedSelected] = useState('reserv');
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);
	const [scroll, setScroll] = useState(false);
	const [info, setInfo] = useState(null);
	const { permissions, error: errorPermissions } = usePermissions(FUNCTIONALITIES.desk, 'WRITE');
	let footerStyle = null;
	const refContainer = useRef(null);
	const refHeader = useRef(null);
	const hasScroll = useScrollBar(refContainer, refHeader, segmentedSelected);
	footerStyle = hasScroll ? styles.hasScroll : styles.noScroll;

	const handleDismissToast = (type: 'error' | 'success' | 'info') => {
		if (type === 'error') {
			setError('');
		}
		if (type === 'success') {
			setSuccess('');
		}
		if (type === 'info') {
			setInfo(null);
		}
	};

	useEffect(() => {
		setError(errorPermissions);
	}, [errorPermissions]);

	useEffect(() => {
		return () => {
			setThereAreUnsavedChanges(false);
		};
	}, []);

	const lockerByDeskMode = sedePortal?.configuration?.lockerEmployeeKey == LOCKERS_MODE.LOCKER_BY_DESK;

	return (
		<IonContent scrollEvents forceOverscroll={true} onIonScroll={(x) => setScroll(x.detail.scrollTop > 0)}>
			<HeaderPage scroll={scroll} title={t('workstations')}>
				<div ref={refHeader}>
					<Segmented value={segmentedSelected}>
						{/* TODO: Uncomment when functionality is required
						<SegmentButton onClick={() => setSegmentedSelected('avaiability')} value={'avaiability'} mode={'md'}>
							<IonLabel>{t('tab_availability')}</IonLabel>
						</SegmentButton>
						*/}
						<SegmentButton onClick={() => setSegmentedSelected('reserv')} value={'reserv'} mode={'md'}>
							<IonLabel>{t('tab_reservations')}</IonLabel>
						</SegmentButton>
						<SegmentButton
							onClick={() => setSegmentedSelected('error_management')}
							value={'error_management'}
							mode={'md'}
						>
							<IonLabel>{t('tab_error_management')}</IonLabel>
						</SegmentButton>
						<SegmentButton onClick={() => setSegmentedSelected('permissions')} value={'permissions'} mode={'md'}>
							<IonLabel>{t('tab_permissions')}</IonLabel>
						</SegmentButton>
						{lockerByDeskMode && (
							<SegmentButton onClick={() => setSegmentedSelected('lockers')} value={'lockers'} mode={'md'}>
								<IonLabel>{t('tab_lockers')}</IonLabel>
							</SegmentButton>
						)}
						<SegmentButton onClick={() => setSegmentedSelected('legends')} value={'legends'} mode={'md'}>
							<IonLabel>{t('tab_legends')}</IonLabel>
						</SegmentButton>

						<SegmentButton onClick={() => setSegmentedSelected('other')} value={'other'} mode={'md'}>
							<IonLabel>{t('tab_other')}</IonLabel>
						</SegmentButton>
					</Segmented>
				</div>
			</HeaderPage>
			<div className="container" ref={refContainer}>
				<div className={segmentedSelected === 'avaiability' ? styles.display : styles.hide}>
					<Availability />
				</div>
				<div className={segmentedSelected === 'reserv' ? styles.display : styles.hide}>
					<TimeAndReservations
						setError={setError}
						setSuccess={setSuccess}
						setInfo={setInfo}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'error_management' ? styles.display : styles.hide}>
					<ErrorManagement
						setError={setError}
						setSuccess={setSuccess}
						setInfo={setInfo}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'permissions' ? styles.display : styles.hide}>
					<WsPermissions
						setError={setError}
						setSuccess={setSuccess}
						setInfo={setInfo}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				{lockerByDeskMode && (
					<div className={segmentedSelected === 'lockers' ? styles.display : styles.hide}>
						<Lockers
							setError={setError}
							setSuccess={setSuccess}
							setInfo={setInfo}
							hasWritePermission={permissions.WRITE}
							footerStyle={footerStyle}
						/>
					</div>
				)}
				<div className={segmentedSelected === 'legends' ? styles.display : styles.hide}>
					<Legends
						setError={setError}
						setSuccess={setSuccess}
						setInfo={setInfo}
						hasWritePermission={permissions.WRITE}
						footerStyle={footerStyle}
					/>
				</div>
				<div className={segmentedSelected === 'other' ? styles.display : styles.hide}>
					<OtherConfigurations />
				</div>
			</div>
			<Feedback error={error} success={success} info={info} onDidDismissToast={handleDismissToast} />
		</IonContent>
	);
};

export default Workstations;
