import { IonRadioGroup, IonRadio, IonLabel } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Item } from '@acciona/ui-ionic-kit';
import dayjs from 'dayjs';
import { RESERVATIONS_SCHEDULES } from '../../../../../utils/constants';
import styles from './styles.module.scss';

type Props = {
	reservationId: number;
	date: string;
	schedule: string;
	selectedVehicle: any;
	userVehicles: any[];
	reservationType: 'reservation' | 'request';
	onChange: (reservationId: number, vehicleId: number) => void;
};

export const EditReservationItem = (props: Props) => {
	const { reservationId, date, schedule, selectedVehicle, userVehicles, reservationType, onChange } = props;
	const { t } = useTranslation();
	const textHeader =
		reservationType === 'reservation' ? t('reservation_vehicle_for_day') : t('request_vehicle_for_day');

	const onChangeHandler = (e: any) => {
		onChange(reservationId, e.target.value);
	};

	const reservationSchedule = RESERVATIONS_SCHEDULES[schedule] ? t(RESERVATIONS_SCHEDULES[schedule]) : null;

	return (
		<div className={styles.reservationContainer}>
			<p>
				{textHeader}
				<b>
					{` ${dayjs(date).format('DD/MM/YYYY')}`}
					{reservationSchedule ? ` (${reservationSchedule})` : ''}
				</b>
				.
			</p>
			<IonRadioGroup
				className={styles.reservationVehicles}
				value={selectedVehicle.vehicleId}
				onIonChange={onChangeHandler}
			>
				{userVehicles
					.sort((a, b) => b.selected - a.selected)
					.map((vehicle: any) => (
						<Item key={vehicle.vehicleId} lines="none" mode="md" type="button">
							<IonRadio
								mode="md"
								color="primary"
								slot="start"
								value={vehicle.vehicleId}
								aria-label={`${date} ${vehicle.brand} ${vehicle.model}`}
							/>
							<IonLabel>{`${vehicle.brand} ${vehicle.model} (${vehicle.plate})`}</IonLabel>
						</Item>
					))}
			</IonRadioGroup>
		</div>
	);
};
