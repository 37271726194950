import React from 'react';
import { InputCustomEvent, IonInput, IonItem } from '@ionic/react';
import { JAVA_INT } from '../../utils/constants';
import styles from './styles.module.scss';

type Props = {
	name: string;
	className: string;
	wrapperClass?: string;
	value: number;
	onIonChange: (e: InputCustomEvent) => void;
	min?: number;
	max?: number;
	placeholder?: string;
	disabled?: boolean;
	'aria-label': string;
	label: string;
	required?: boolean;
	defaultValue?: number;
	isValid?: boolean;
	slot?: string;
	wrapperSlot?: string;
	labelPlacement?: 'fixed' | 'start' | 'end' | 'floating' | 'stacked' | undefined;
	keysDisabled?: boolean;
	allowZero?: boolean;
};

export const NumberInput: React.FC<Props> = ({
	wrapperClass,
	name,
	className,
	value,
	onIonChange,
	min = JAVA_INT.MIN,
	max = JAVA_INT.MAX,
	'aria-label': ariaLabel,
	label,
	placeholder,
	required,
	disabled,
	isValid = true,
	defaultValue = 0,
	slot = '',
	wrapperSlot = '',
	labelPlacement,
	keysDisabled,
}) => {
	const onIonChangeWrapper = (e: InputCustomEvent) => {
		const newValue = e.detail.value;
		if (+newValue < min) {
			e.target.value = min;
		} else if (+newValue > max) {
			e.target.value = max;
		}
		onIonChange(e);
	};

	return (
		<IonItem
			lines="none"
			className={`${styles.inputModal} ${styles.inputWithLabel} ${wrapperClass} ${!isValid && styles.errorInput} ${
				defaultValue !== value && styles.touchedInput
			}`}
			slot={wrapperSlot}
		>
			<IonInput
				name={name}
				className={className}
				type="number"
				inputmode="numeric"
				pattern="numeric"
				value={value}
				defaultValue={defaultValue}
				onIonChange={onIonChangeWrapper}
				min={min}
				max={max}
				disabled={disabled}
				aria-label={ariaLabel}
				label={label}
				required={required}
				placeholder={placeholder}
				slot={slot}
				labelPlacement={labelPlacement}
				onKeyDown={keysDisabled ? (e) => e.preventDefault() : () => {}}
			></IonInput>
		</IonItem>
	);
};
