import { useEffect, useMemo, useRef, useState } from 'react';
import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import {
	PaginationState,
	useReactTable,
	getCoreRowModel,
	ColumnDef,
	createColumnHelper,
	getSortedRowModel,
	SortingState,
} from '@tanstack/react-table';
import { Toast } from '@acciona/ui-ionic-kit';
import { parkingService } from '../../../_api/services/parking';
import { ParkingPoints } from '../../../_api/services/parking/types';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import _ from 'lodash';
import { TablePaginated } from './components/TablePaginated';
import { ManualPoints } from './components/ManualPoints/ManualPoints';
import { ModalEditPoints } from './components/ModalEditPoints/ModalEditPoints';
import { editOptions, getSuccessMessage } from './helpers';
import { IndeterminateCheckbox } from '../../../components/Table/IndeterminateCheckbox';
import { authServices } from '../../../_api/services/auth';
import useAppContext from '../../../hooks/useAppContext';
import { Locale } from './types';
import { allRowsAreSelected, getNewAllSelectedRows, getNewSelectedRows, rowIsSelected } from '../../../utils/table';
import { EditionModeSelector } from './components/EditionModeSelector/EditionModeSelector';
import { ModalEditVehicle } from './components/ModalEditVehicle/ModalEditVehicle';
import { ModalEditReservation } from './components/ModalEditReservation/ModalEditReservation';
import styles from './styles.module.scss';

export const ParkingUsers: React.FC = () => {
	const { t } = useTranslation();
	const { userLanguage } = useAppContext();
	const queryClient = useQueryClient();
	const columnHelper = createColumnHelper<ParkingPoints>();
	const defaultData = useMemo(() => [], []);
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);
	const [message, setMessage] = useState('');
	const [search, setSearch] = useState('');
	const [searchQuery, setSearchQuery] = useState('');
	const [showModalEditPoints, setShowModalEditPoints] = useState(false);
	const [showModalEditVehicle, setShowModalEditVehicle] = useState(false);
	const [showModalEditReservation, setShowModalEditReservation] = useState(false);
	const [selectedRows, setSelectedRows] = useState([]);
	const [sorting, setSorting] = useState<SortingState>([{ id: 'fullName', desc: false }]);

	const [hasWritePermission, setHasWritePermission] = useState(false);
	const mountedRef = useRef(true);

	useEffect(() => {
		if (mountedRef.current) {
			authServices
				.checkPermission('PARKING', 'WRITE')
				.then((resultQuery) => {
					mountedRef.current && setHasWritePermission(resultQuery?.hasPermission);
				})
				.catch(() => {
					mountedRef.current && setHasWritePermission(false);
				});
		}

		return () => {
			mountedRef.current = false;
		};
	}, []);

	const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const pagination = useMemo(
		() => ({
			pageIndex,
			pageSize,
		}),
		[pageIndex, pageSize],
	);

	const fetchDataOptions = {
		pageNumber: pageIndex + 1,
		pageSize: pageSize,
		search: searchQuery,
		asc: sorting[0]?.desc ? false : true,
	};

	const { data: queryData, isFetching } = useQuery(
		['data', fetchDataOptions],
		async () => await parkingService.getEmployeesPoints(fetchDataOptions),
		{
			keepPreviousData: true,
			refetchOnWindowFocus: false,
			onError: (error) => {
				setMessage(error as string);
				setError(true);
			},
		},
	);

	const { mutate: updateTableData, isLoading: isLoadingSave } = useMutation(
		(updatedRows: {}[]) => {
			setMessage(getSuccessMessage(updatedRows));
			const dataToUpdate = updatedRows.map((row) => _.omit(row, ['fullName']));
			return parkingService.updateEmployeesPoints(dataToUpdate);
		},
		{
			onSuccess: () => {
				queryClient.refetchQueries('data');
				setSelectedRows([]);
				setSuccess(true);
			},
			onError: (error) => {
				setMessage(error as string);
				setError(true);
			},
		},
	);

	const handleEditPoints = (selected) => {
		setSelectedRows(selected);
		setShowModalEditPoints(selected.length > 0);
	};

	const handleSelectEditOption = (selected, value) => {
		if (value === 1) {
			handleEditPoints(selected);
		} else if (value === 2) {
			setSelectedRows(selected);
			setShowModalEditVehicle(selected.length > 0);
		} else if (value === 3) {
			setSelectedRows(selected);
			setShowModalEditReservation(selected.length > 0);
		}
	};

	const resetPagination = () => {
		setPagination((prev) => ({
			pageIndex: 0,
			pageSize: prev.pageSize,
		}));
	};

	const toggleSort = () => {
		setSorting((prev) => [{ id: 'fullName', desc: !prev[0].desc }]);
	};

	const resetSort = () => {
		setSorting([{ id: 'fullName', desc: false }]);
	};

	useEffect(() => {
		let isMounted = true;

		if (isMounted && search !== searchQuery) {
			if (search.length > 2) {
				setSearchQuery(search);
			} else {
				setSearchQuery('');
			}
			resetPagination();
			resetSort();
		}
		return () => {
			isMounted = false;
		};
	}, [search]);

	const selectRowHandler = (rowSelected) => {
		setSelectedRows((prev) => getNewSelectedRows(prev, rowSelected, 'employeeId'));
	};

	const selectAllRowsInPageHandler = (currentPageRows) => {
		setSelectedRows((prev) => getNewAllSelectedRows(prev, currentPageRows, 'employeeId'));
	};

	const columns = useMemo<ColumnDef<ParkingPoints, any>[]>(
		() => [
			{
				id: 'select',
				header: ({ table }) => {
					const currentPageRows = table.getRowModel().rows.map((row) => row.original);

					return (
						<div>
							<IndeterminateCheckbox
								indeterminate={table.getIsSomeRowsSelected()}
								checked={allRowsAreSelected(currentPageRows, selectedRows, 'employeeId')}
								onChange={() => selectAllRowsInPageHandler(currentPageRows)}
								disabled={!hasWritePermission}
							/>
						</div>
					);
				},
				cell: ({ row }) => (
					<div>
						<IndeterminateCheckbox
							indeterminate={row.getIsSomeSelected()}
							checked={rowIsSelected(selectedRows, row.original, 'employeeId')}
							onChange={() => selectRowHandler(row.original)}
							disabled={!hasWritePermission}
						/>
					</div>
				),
				size: 15,
				enableSorting: false,
			},
			columnHelper.accessor('fullName', {
				header: () => t('table_header_fullname'),
				cell: (info) => info.getValue(),
				size: 100,
			}),
			columnHelper.accessor('profile', {
				header: () => t('table_header_profile'),
				cell: (info) => info.getValue() || '',
				size: 100,
				enableSorting: false,
			}),
			columnHelper.accessor('employeePoints', {
				header: () => t('table_header_employee_points'),
				cell: (info) => info.renderValue(),
				size: 50,
				enableSorting: false,
			}),
			columnHelper.accessor('manualPoints', {
				header: t('table_header_manual_points'),
				cell: (info) => <ManualPoints points={info.renderValue() || 0} />,
				size: 50,
				enableSorting: false,
			}),
			columnHelper.accessor('totalPoints', {
				header: () => t('table_header_total_points'),
				cell: (info) => info.renderValue(),
				size: 50,
				enableSorting: false,
			}),
			columnHelper.accessor('plannedDate', {
				header: () => t('table_header_planned_date'),
				cell: (info) => info.getValue() || '-',
				size: 110,
				enableSorting: false,
			}),
			{
				id: 'edit',
				cell: ({ row }) => (
					<EditionModeSelector
						hasWritePermission={hasWritePermission}
						onChange={handleSelectEditOption}
						row={row.original}
						actions={editOptions}
					/>
				),
				size: 40,
				enableSorting: false,
			},
		],
		[selectedRows, hasWritePermission, t],
	);

	const handleChangeSorting = () => {
		toggleSort();
		resetPagination();
	};

	const table = useReactTable({
		data: queryData?.results ?? defaultData,
		columns,
		pageCount: queryData?.totalPages ?? 0,
		state: {
			pagination,
			sorting,
		},
		onSortingChange: handleChangeSorting,
		onPaginationChange: setPagination,
		getSortedRowModel: getSortedRowModel(),
		getCoreRowModel: getCoreRowModel(),
		manualPagination: true,
		filterFns: undefined,
	});

	const setFeedback = (type: 'success' | 'error', text: string) => {
		setMessage(text);
		if (type === 'success') {
			setSuccess(true);
		} else if (type === 'error') {
			setError(true);
		}
	};

	return (
		<>
			<div className={styles.h3}>{t('lbl_users')}</div>
			<div className={styles.footnote}>{t('parking_users_subtitle')}</div>

			<TablePaginated
				key={userLanguage.code}
				table={table}
				isFetching={isFetching}
				onEdit={handleEditPoints}
				selectedRows={selectedRows}
				totalRows={queryData?.totalRegisters}
				totalSelectedRows={selectedRows.length}
				search={search}
				setSearch={setSearch}
				searchPlaceholder={t('plholder_search_user')}
				pageSize={pagination.pageSize}
				hasWritePermission={hasWritePermission}
			/>
			<ModalEditPoints
				showModal={showModalEditPoints}
				onClose={() => setShowModalEditPoints(false)}
				selectedRows={selectedRows}
				updateTableData={updateTableData}
				userLanguage={userLanguage.code as Locale}
				hasWritePermission={hasWritePermission}
			/>
			{showModalEditVehicle && (
				<ModalEditVehicle
					showModal={showModalEditVehicle}
					onClose={() => setShowModalEditVehicle(false)}
					selectedRows={selectedRows}
					updateTableData={updateTableData}
					userLanguage={userLanguage.code as Locale}
					hasWritePermission={hasWritePermission}
					setFeedback={setFeedback}
				/>
			)}
			{showModalEditReservation && (
				<ModalEditReservation
					key={`modal-edit-reservation-${selectedRows[0]?.employeeId}`}
					showModal={showModalEditReservation}
					onClose={() => setShowModalEditReservation(false)}
					selectedRows={selectedRows}
					updateTableData={updateTableData}
					userLanguage={userLanguage.code as Locale}
					hasWritePermission={hasWritePermission}
					setFeedback={setFeedback}
				/>
			)}
			<IonLoading isOpen={isLoadingSave} message={t('msg_loading')} duration={0} />
			<Toast isOpen={error} message={message} onDidDismiss={() => setError(false)} position="bottom" type="error" />
			<Toast
				isOpen={success}
				message={message}
				onDidDismiss={() => setSuccess(false)}
				position="bottom"
				type="success"
			/>
		</>
	);
};
