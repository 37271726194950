import { useEffect, useMemo, useState } from 'react';
import { IonLabel, IonLoading } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { Button, Icon, List, Toast } from '@acciona/ui-ionic-kit';
import { District } from '../../../_api/services/workstation/types';
import useAppContext from '../../../hooks/useAppContext';
import styles from './styles.module.scss';
import { ModalAddZone } from '../../../components/ModalAddZone/ModalAddZone';
import { ZoneList } from '../../../components/ZonesList/ZoneList';
import { roomsServices } from '../../../_api/services/rooms';
import { RoomsDistricts } from '../../../_api/services/rooms/types';

type Props = {
	setError?: Function;
	setSuccess?: Function;
	setInfo?: Function;
	hasWritePermission?: boolean;
	footerStyle: string;
};

const findDifferentDistricts = (newDistricts: District[], initialDistricts: District[]): District[] => {
	return newDistricts.filter(
		(district, index) =>
			!_.isEqual(district.overflowDistricts.sort(), initialDistricts[index].overflowDistricts.sort()),
	);
};

const hasEmptyValues = (formValues: any) =>
	Object.values(formValues).some((value) => value === null || value === undefined);

export const Permissions: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	const { setError, setSuccess, hasWritePermission } = props;

	const defaultValues: RoomsDistricts = {
		districts: [],
	};
	const [showAddZoneModal, setShowAddZoneModal] = useState(false);
	const [values, setValues] = useState<RoomsDistricts>(defaultValues);
	const [toastMessage, setToastMessage] = useState(null);
	const [zoneToEdit, setZoneToEdit] = useState<District>(null);
	const [districts, setDistricts] = useState<District[]>([]);

	const queryClient = useQueryClient();
	const { setThereAreUnsavedChanges } = useAppContext();

	const { isLoading: isLoading, data: initialValues } = useQuery(
		['roomsOverflow'],
		async () => roomsServices.getOverflowDistricts(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setValues(data);
				setDistricts(data.districts);
			},
		},
	);

	const { isLoading: loadingSave, mutate: handleSave } = useMutation(roomsServices.updateOverflowDistricts, {
		onSuccess: () => {
			setSuccess(t('msg_success'));
			queryClient.refetchQueries('roomsOverflow');
		},
		onError: (error) => {
			setError(error as string);
		},
	});

	const isEdited = useMemo(() => {
		return !_.isEqual(initialValues, values) || !_.isEqual(initialValues.districts, districts);
	}, [initialValues, values, districts]);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleAddZone = (zone: District) => {
		const districtExists = districts.some((district) => district.districtName === zone.districtName);
		if (districtExists) {
			const updatedDistricts = districts.map((district) => {
				if (district.districtName === zone.districtName) {
					return {
						...district,
						overflowDistricts: zone.overflowDistricts,
					};
				}
				return district;
			});
			setDistricts(updatedDistricts);
		} else {
			const newDistricts = [...districts, zone];
			setDistricts(newDistricts);
		}
		setToastMessage(t('desk_permissions_toast.edit_add_confirm'));
	};

	const handleEditZone = (zone: string) => {
		const districtToEdit = districts.find((d) => d.districtName === zone);
		setZoneToEdit(districtToEdit);
		setShowAddZoneModal(true);
	};

	const handleRemoveZone = (zone: string) => {
		const updatedDistricts = districts.map((d) => {
			if (d.districtName === zone) {
				return { ...d, overflowDistricts: [] };
			} else {
				return d;
			}
		});
		setDistricts(updatedDistricts);
		setToastMessage(t('desk_permissions_toast.delete_confirm'));
	};

	const handleOnSave = () => {
		const differentOverflowDistrictsObjects = findDifferentDistricts(districts, initialValues.districts);

		const updatedData = _.cloneDeep(values);
		updatedData.districts = differentOverflowDistrictsObjects;

		if (hasEmptyValues(updatedData)) {
			setError(t('msg_error_all_fields_required'));
		} else {
			handleSave(updatedData);
		}
	};

	return (
		<>
			{isLoading ? (
				<IonLoading isOpen={isLoading || loadingSave} message={t('msg_loading')} duration={0} />
			) : (
				<>
					<ModalAddZone
						showModal={showAddZoneModal}
						onClose={() => {
							setZoneToEdit(null);
							setShowAddZoneModal(false);
						}}
						saveChanges={handleAddZone}
						hasWritePermission={hasWritePermission}
						districts={districts}
						zoneToEdit={zoneToEdit}
					/>
					<List>
						<div className={styles.element}>
							<h3 className={styles.blockTitle}>{t('districts_overflow')}</h3>
							<p className={`${styles.blockSubtitle}`}>{t('districts_overflow_description')}</p>
							<div className={styles.pageContent}>
								<ZoneList
									hasWritePermission={hasWritePermission}
									districtsConfig={districts.filter((d) => d.overflowDistricts.length > 0)}
									handlerDeleteSubcat={handleRemoveZone}
									handlerEditZone={handleEditZone}
								/>
							</div>
							{hasWritePermission && (
								<Button
									slot="start"
									className={styles.addZoneBtn}
									onClick={() => {
										setShowAddZoneModal(true);
									}}
								>
									<Icon className="icon icon-plus iconPlus" />
									<IonLabel>{t('btn_add_zone')}</IonLabel>
								</Button>
							)}
						</div>
					</List>
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button onClick={handleOnSave} className={styles.btnHeader} disabled={!isEdited} color="primary">
								{t('btn_save_data')}
							</Button>
						</div>
					)}

					<Toast
						isOpen={!!toastMessage}
						message={toastMessage}
						onDidDismiss={() => setToastMessage(null)}
						position="bottom"
						type="success"
					/>
				</>
			)}
		</>
	);
};
