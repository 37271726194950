import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import { IonContent, IonLabel, IonTitle, IonToolbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';

export type Props = {
	showModal: boolean;
	onConfirm: Function;
	onClose: Function;
	title?: string;
	description?: string;
	confirmText?: string;
	cancelText?: string;
};

export const CustomModal: React.FC<Props> = ({
	showModal,
	onConfirm,
	onClose,
	title,
	description,
	confirmText,
	cancelText,
}) => {
	const { t } = useTranslation();

	return (
		<Modal
			isOpen={showModal}
			onDidDismiss={() => {
				onClose();
			}}
			className={styles.modal}
		>
			<IonToolbar>
				<Button slot="end" fill={'clear'} color="light" className={styles.closeModalBtn} onClick={() => onClose()}>
					<IonLabel className="dark">
						<Icon className="icon icon24 icon-close"></Icon>
					</IonLabel>
				</Button>
				<IonTitle className="centerTitle">
					<h3>
						<b>{title}</b>
					</h3>
				</IonTitle>
			</IonToolbar>
			<IonContent>
				<div className={styles.logoutContainer}>
					<div>{description}</div>
					<div>
						<div>
							<Button
								className={`${styles.secondaryBtn} ${styles.btnHeader} ${styles.space} `}
								onClick={() => {
									onClose();
								}}
							>
								{cancelText ?? t('no')}
							</Button>
						</div>
						<div>
							<Button color="primary" className={styles.btnHeader} onClick={() => onConfirm()}>
								{confirmText ?? t('yes')}
							</Button>
						</div>
					</div>
				</div>
			</IonContent>
		</Modal>
	);
};

export default CustomModal;
