import { useMemo, useState } from 'react';
import { IonToolbar, IonTitle, IonFooter, IonLabel, IonRadio, IonRadioGroup, IonText, IonContent } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { ColumnDef } from '@tanstack/react-table';
import _ from 'lodash';
import { Button, Icon, Item } from '@acciona/ui-ionic-kit';
import { Employee } from '../../../../_api/services/roles/types';
import Table from '../../../../components/Table/Table';
import { ConfigurationInputs } from './ConfigurationInputs';
import { USER_APP_PERMISSIONS_TYPES } from '../../../../utils/constants';
import { getSortDateString } from '../../../../utils/functions';
import styles from './styles.module.scss';

type Props = {
	mode: 'add' | 'edit';
	title: string;
	subtitle: string;
	data: Employee[];
	setData: (data: Employee[]) => void;
	onClose: () => void;
	onSave: () => void;
	onBack?: () => void;
	showDetails?: boolean;
};

const dateToString = (date: Date) => {
	return date ? getSortDateString(new Date(date)) : '';
};

export const ModalContentConfiguration: React.FC<Props> = (props) => {
	const { mode, title, subtitle, data, setData, onClose, onBack = null, onSave, showDetails } = props;
	const { t } = useTranslation();

	const EDITION_OPTIONS = {
		SAME_PERMISSION_FOR_ALL_USERS: {
			value: '0',
			description: t('modal_add_users_edition_options.0'),
		},
		INDIVIDUAL_PERMISSIONS: {
			value: '1',
			description: t('modal_add_users_edition_options.1'),
		},
	};
	const [selectedEditionOption, setSelectedEditionOption] = useState(
		EDITION_OPTIONS.SAME_PERMISSION_FOR_ALL_USERS.value,
	);

	const onChangeEditionOption = (e) => {
		setSelectedEditionOption(e.detail.value);
	};

	const updateData = (nextData: any) => {
		const newData = nextData.map((user) => {
			return {
				...user,
				startDate: dateToString(user?.startDate),
				endDate: dateToString(user?.endDate),
				permissionType: user?.permissionType ?? USER_APP_PERMISSIONS_TYPES.undefined,
			};
		});
		setData(newData);
	};

	const setStartDate = (date: Date, employeeId?: number) => {
		const nextData = data.map((user) => ({
			...user,
			startDate: !employeeId || user.employeeId === employeeId ? date : user.startDate,
		}));

		updateData(nextData);
	};

	const setEndDate = (date: Date, employeeId?: number) => {
		const nextData = data.map((user) => ({
			...user,
			endDate: !employeeId || user.employeeId === employeeId ? date : user.endDate,
		}));

		updateData(nextData);
	};

	const setPermissionSelected = (permission: string, employeeId?: number) => {
		const nextData = data.map((user) => ({
			...user,
			permissionType: !employeeId || user.employeeId === employeeId ? permission : user.permissionType,
		}));
		updateData(nextData);
	};

	const onCloseHandler = () => {
		onClose();
	};

	const handleSaveChanges = () => {
		onSave();
	};

	const checkSaveIsDisabled = () => {
		const someFieldValueIsNotValid = data.some((item) => {
			if (item?.permissionType === USER_APP_PERMISSIONS_TYPES.start_date) {
				return !item.startDate;
			} else if (item?.permissionType === USER_APP_PERMISSIONS_TYPES.temporal) {
				return !item.startDate || !item.endDate;
			} else {
				return false;
			}
		});
		return someFieldValueIsNotValid;
	};

	const columns = useMemo<ColumnDef<Employee, any>[]>(() => {
		const columnName: ColumnDef<Employee, any> = {
			id: 'fullName',
			accessorKey: 'fullName',
			header: () => t('table_header_fullname'),
			cell: ({ row }) =>
				`${row.original.employeeId} - ${row.original.fullName} ${
					row.original?.profile ? `(${row.original.profile})` : ''
				}`,
			size: 100,
		};

		const columnTypePermission: ColumnDef<Employee, any> = {
			id: 'permissionType',
			accessorKey: 'permissionType',
			header: () => t('lbl_type_of_permission'),
			cell: ({ row }) => t(`app_permissions.permission_types.${row.original.permissionType}`),
			size: 100,
		};
		if (showDetails) {
			return [columnName, columnTypePermission];
		}
		return [columnName];
	}, [showDetails]);

	return (
		<>
			<IonToolbar>
				{!!onBack && (
					<Button
						slot="start"
						fill={'clear'}
						color="light"
						className={styles.closeModalBtn}
						aria-label={t('lbl_aria_close_modal')}
						onClick={onBack}
					>
						<Icon className="icon icon-chevron-left" aria-hidden="true"></Icon>
					</Button>
				)}
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('lbl_aria_close_modal')}
					onClick={onCloseHandler}
				>
					<Icon className="icon icon-close" aria-hidden="true"></Icon>
				</Button>
				<IonTitle className={styles.toolbarTitle}>{title}</IonTitle>
			</IonToolbar>
			<IonText className={`${styles.textInstructions} ion-text-wrap block`}>{subtitle}</IonText>
			{mode === 'add' && data.length > 1 && (
				<IonRadioGroup
					className={styles.editionOptions}
					value={selectedEditionOption}
					onIonChange={onChangeEditionOption}
				>
					{Object.values(EDITION_OPTIONS).map((item) => (
						<Item key={item.value} lines="none" mode="md" type="button">
							<IonRadio
								mode="md"
								color="primary"
								slot="start"
								value={item.value}
								aria-label={item.description}
							/>
							<IonLabel>{item.description}</IonLabel>
						</Item>
					))}
				</IonRadioGroup>
			)}
			{selectedEditionOption === EDITION_OPTIONS.SAME_PERMISSION_FOR_ALL_USERS.value ? (
				<IonContent scrollY={false}>
					{/* SAME PERMISSIONS FOR ALL USERS */}
					{!_.isEmpty(data) && (
						<>
							<ConfigurationInputs
								user={data[0]}
								setStartDate={setStartDate}
								setEndDate={setEndDate}
								setPermissionSelected={setPermissionSelected}
								samePermissionForAllUsers
							/>
							<Table showHeader={false} initialData={data} columns={columns} maxHeight="240px" />
						</>
					)}
				</IonContent>
			) : (
				<IonContent scrollY={true}>
					{/* INDIVIDUAL PERMISSIONS */}
					<p className={styles.labelCountSelectedUsers}>{`${data.length} ${t('lbl_selected_users')}`}</p>
					{!_.isEmpty(data) &&
						data.map((user) => (
							<div key={user.employeeId} className={styles.individualPermission}>
								<div
									className={styles.userName}
								>{`${user.employeeId} - ${user.fullName} (${user.profile})`}</div>
								<ConfigurationInputs
									user={user}
									setStartDate={setStartDate}
									setEndDate={setEndDate}
									setPermissionSelected={setPermissionSelected}
								/>
							</div>
						))}
				</IonContent>
			)}
			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={onCloseHandler}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						onClick={handleSaveChanges}
						disabled={checkSaveIsDisabled()}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</>
	);
};
