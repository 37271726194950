import _ from 'lodash';
import { IEmployeeResult } from './types';
import { diacriticalReplace } from '../../utils/functions';

export const matchesUserSearch = (search: string, user: IEmployeeResult) => {
	return (
		_.isEmpty(search) ||
		diacriticalReplace(`${user.employeeId} - ${user.fullName}`)
			.toLowerCase()
			.includes(diacriticalReplace(search).toLowerCase())
	);
};

export const getFilteredUsers = (dataUsers: IEmployeeResult[], showOnlyCheckeds: boolean, userNameSearch: string) => {
	if (_.isEmpty(dataUsers)) {
		return [];
	}

	const result = dataUsers.filter((user) => {
		const show = !showOnlyCheckeds || (showOnlyCheckeds && user.isChecked);
		return show && matchesUserSearch(userNameSearch, user);
	});

	return result.sort((a, b) => {
		return diacriticalReplace(a.fullName).localeCompare(diacriticalReplace(b.fullName));
	});
};
