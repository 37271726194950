import { useState, useEffect } from 'react';
import _ from 'lodash';
import { authServices } from '../_api/services/auth';
import { tokenServices } from '../_api/services/token';
import { userServices } from '../_api/services/user';
import { history } from '../_helpers/history';
import useAppContext from './useAppContext';
import { ConfigurationPreference, Sede } from '../_api/services/user/types';
import { CONFIGURATION_PREFERENCES } from '../utils/constants';
import { SplitMenuItem } from '../components/SplitPane/menuConfiguration';
import { getPreferencesOptions, sortSedesList } from '../utils/functions';

export const useInitialConfig = () => {
	const {
		setAuth,
		sedePortal,
		setSedePortal,
		setUserLanguage,
		sedePortalOptions,
		updateSedePortal,
		updateSedePortalOptions,
		menuOptions,
		configurationPreference,
		updateConfigurationPreference,
		transversal,
	} = useAppContext();

	const [isLoading, setLoading] = useState(false);
	const [msgNotPermission, setMsgNotPermission] = useState(false);
	const [showChangeSedeModal, setShowChangeSedeModal] = useState(false);
	const [preferencesOptions, setPreferencesOptions] = useState(null);
	const [userIsLogged, setUserIsLogged] = useState(false);

	const getUserConfig = async () => {
		setUserIsLogged(true);
		setLoading(true);
		const {
			defaultSede,
			sedesList,
			transversal: hasTransversalAccess = false,
			configurationPreference: preference = null,
		} = await userServices.getUserSedePortal();
		const sedes = sortSedesList(sedesList, defaultSede, 'description');

		if (sedePortal) {
			// if is changing sede from menu modal, logged in the app
			history.replace({
				pathname: '/login',
				state: { forcedRefresh: true },
			});
			return;
		}

		updateSedePortalOptions(sedes, defaultSede, hasTransversalAccess, preference);

		const defaultLanguage = await userServices.getUserLanguageConfig();
		setUserLanguage(defaultLanguage);
		setLoading(false);
	};

	useEffect(() => {
		if (sedePortalOptions === null || transversal === null) {
			return;
		}
		setPreferencesOptions(getPreferencesOptions(transversal, sedePortalOptions));
	}, [transversal, sedePortalOptions]);

	useEffect(() => {
		if (!preferencesOptions) {
			return;
		}

		if (!configurationPreference) {
			initializeConfigPreferences(configurationPreference, preferencesOptions);
		}

		if (!sedePortal) {
			initializeDefaultSede(sedePortal, sedePortalOptions, configurationPreference);
		}
	}, [sedePortal, sedePortalOptions, configurationPreference, transversal, preferencesOptions]);

	const initializeConfigPreferences = (
		defaultPreference: ConfigurationPreference,
		preferencesOptions: {
			id: string;
			description: string;
		}[],
	) => {
		setMsgNotPermission(false);
		const hasDefaultPreference = !!defaultPreference && defaultPreference !== '';

		if (hasDefaultPreference) {
			return;
		} else if (preferencesOptions.length === 0) {
			setMsgNotPermission(true);
		} else if (preferencesOptions.length === 1) {
			updateConfigurationPreference(preferencesOptions[0].id);
		} else if (preferencesOptions.length > 1) {
			setShowChangeSedeModal(true);
		}
	};

	const initializeDefaultSede = (defaultSede: Sede, availableSedes: Sede[], configurationPreference: string) => {
		if (defaultSede) {
			setSedePortal(defaultSede);
		} else if (availableSedes.length === 1) {
			setSedePortal(availableSedes[0]);
		} else if (configurationPreference === CONFIGURATION_PREFERENCES.sede.id && availableSedes.length > 1) {
			setShowChangeSedeModal(true);
		}
	};

	const loadDefaultPage = async (menuItems: SplitMenuItem[]) => {
		setMsgNotPermission(false);
		let hasPermission = false;

		for (const menuItem of menuItems) {
			const resultQuery = await authServices.checkPermission(menuItem.functionality, 'READ');
			if (resultQuery.hasPermission) {
				hasPermission = true;
				history.replace(menuItem.link);
				break;
			}
		}
		setAuth(hasPermission);
		!hasPermission && setMsgNotPermission(true);
		setLoading(false);
	};

	useEffect(() => {
		if (!_.isEmpty(tokenServices.getAccount())) {
			if (transversal && configurationPreference === CONFIGURATION_PREFERENCES.transversal.id) {
				loadDefaultPage(menuOptions);
			} else if (sedePortal) {
				userServices.setUserDefaultSedePortal(sedePortal);
				loadDefaultPage(menuOptions);
			} else if (!sedePortal) {
				getUserConfig();
			}
		}
	}, [sedePortal, transversal, configurationPreference, menuOptions]);

	const changeSedeModalOnSave = (sede: Sede, preference: ConfigurationPreference) => {
		updateSedePortal(sede, preference);
		setShowChangeSedeModal(false);
	};

	return {
		getUserConfig,
		msgNotPermission,
		isLoading,
		setLoading,
		userIsLogged,
		changeSedeModal: {
			show: showChangeSedeModal,
			options: sedePortalOptions,
			onClose: () => setShowChangeSedeModal(false),
			onSave: changeSedeModalOnSave,
		},
	};
};
