import styles from './styles.module.scss';

type Props = {
	checked: boolean;
	onChange: () => void;
	disabled?: boolean;
};

export const Toggle = (props: Props) => {
	return (
		<label className={styles.switch}>
			<input type="checkbox" disabled={props.disabled} checked={props.checked} onChange={props.onChange} />
			<span className={styles.slider}></span>
		</label>
	);
};
