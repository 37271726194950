import { IonSelect, IonSelectOption } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Icon } from '@acciona/ui-ionic-kit';
import { Table } from '@tanstack/react-table';
import { ExternalSearch } from '../ExternalSearch';
import styles from './styles.module.scss';

type Props = {
	table?: Table<any>;
	showExternalSort?: boolean;
	showSelectedInfo?: boolean;
	handleChangeSort?: (event: any) => void;
	sortColumn?: string;
	sortPlaceholder?: string;
	searchColumn?: string;
	searchPlaceholder?: string;
	totalRows?: number;
	totalSelectedRows?: number;
	handleEdit?: (selected: any) => void;
	handleDelete?: (selected: any) => void;
	hasWritePermission?: boolean;
};

export const TableHeader: React.FC<Props> = (props) => {
	const { t } = useTranslation();
	return (
		<div className={styles.headerContainer}>
			<div className={styles.searchContainer}>
				<ExternalSearch column={props.table.getColumn(props.searchColumn)} placeholder={props.searchPlaceholder} />
			</div>
			{props.showExternalSort && (
				<div>
					<IonSelect
						className={`${styles.input} ${styles.select}`}
						placeholder={props.sortPlaceholder}
						onIonChange={props.handleChangeSort}
						interface="popover"
						aria-label="de A a Z | de Z a A"
					>
						<IonSelectOption value={'de A a Z'}>de A a Z</IonSelectOption>
						<IonSelectOption value={'de Z a A'}>de Z a A</IonSelectOption>
					</IonSelect>
				</div>
			)}
			{props.showSelectedInfo && (
				<div className={styles.selectedInfo}>
					<div>{`${t('table_total_registers')}: ${props.totalRows ?? 0}`}</div>
					<div>{`${t('table_selected_registers')}: ${props.totalSelectedRows}`}</div>
					{props.hasWritePermission && (
						<>
							<button
								hidden={props.totalSelectedRows === 0}
								className={styles.button}
								onClick={props.handleEdit}
							>
								<Icon className="icon icon-edit" />
								<span>{t('table_edit')}</span>
							</button>
							<button
								hidden={props.totalSelectedRows === 0}
								className={styles.button}
								onClick={props.handleDelete}
							>
								<Icon className="icon icon-delete" />
								<span>{t('delete_text')}</span>
							</button>
						</>
					)}
				</div>
			)}
		</div>
	);
};
