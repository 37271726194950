import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { InputCustomEvent, IonItem, IonLabel, IonLoading } from '@ionic/react';
import { Button, List, MultiSelect } from '@acciona/ui-ionic-kit';
import { workstationService } from '../../../_api/services/workstation';
import { ReservationPolicy } from '../../../_api/services/workstation/types';
import useAppContext from '../../../hooks/useAppContext';
import CustomModal from '../../../components/CustomModal/CustomModal';
import { TurningPointsConfiguration } from './components/TurningPointsConfiguration/TurningPointsConfiguration';
import {
	POLICIES_AVAILABLE_OPTIONS,
	POLICIES_SERVICE,
	SEDE_POLICIES,
	defaultWorkstationReservationsConfig,
	defaultTurningPointsConfig,
	getAvailableOptions,
	getSelectedOption,
	getSelectedPolicies,
	getUpdatedPolicies,
	hasEmptyValues,
	validateHours,
} from '../../../utils/reservations';
import { TimeAndReservationsProps, IndexedPolicy } from './types';
import { roundHour } from '../../../utils/functions';
import { Toggle } from '../../../components/Toggle/Toggle';
import { HourSelector } from '../../../components/HourSelector/HourSelector';
import { DefaultPreferencesConfiguration } from '../../../components/DefaultPreferencesConfiguration/DefaultPreferencesConfiguration';
import styles from './styles.module.scss';
import { NumberInput } from '../../../components/NumberInput/NumberInput';

export const TimeAndReservations: React.FC<TimeAndReservationsProps> = (props) => {
	const { setError, setSuccess, hasWritePermission } = props;
	const { setThereAreUnsavedChanges, sedePortal } = useAppContext();
	const { t } = useTranslation();
	const [reservationsConfig, setReservationsConfig] = useState(defaultWorkstationReservationsConfig);
	const [turningPointsConfig, setTurningPointsConfig] = useState(defaultTurningPointsConfig);
	const [sedeDefaultPoliciesConfig, setSedeDefaultPoliciesConfig] = useState<ReservationPolicy[]>([]);
	const [policiesAvailableInThisSede, setPoliciesAvailableInThisSede] = useState<string[]>([]);
	const [middayHour, setMiddayHour] = useState('');
	const [minReservationHour, setMinReservationHour] = useState('');
	const [maxReservationHour, setMaxReservationHour] = useState('');
	const [showFavouritesRestrictionModal, setShowFavouritesRestrictionModal] = useState(false);
	const [showInvalidScheduleConfigurationModal, setShowInvalidScheduleConfigurationModal] = useState(false);
	const [showInvalidDefaultScheduleConfigurationModal, setShowInvalidDefaultScheduleConfigurationModal] =
		useState(false);

	const sedeDefaultPoliciesConfigCurrent = useMemo(
		() => sedeDefaultPoliciesConfig.find((p) => p?.isDefault),
		[sedeDefaultPoliciesConfig],
	);

	const queryClient = useQueryClient();

	const { isLoading: isLoadingReservationsConfig, data: initialReservationsConfig } = useQuery(
		['reservationsConfig'],
		async () => workstationService.getReservationsConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setReservationsConfig(data);
				const indexedPolicies: IndexedPolicy[] = _.keyBy(data.policies, 'name');
				setPoliciesAvailableInThisSede(getSelectedPolicies(indexedPolicies));
				setMiddayHour(indexedPolicies[POLICIES_SERVICE.Morning]?.fullHoraMax);
				setMinReservationHour(indexedPolicies[POLICIES_SERVICE.Personalizada]?.fullHoraMin);
				setMaxReservationHour(indexedPolicies[POLICIES_SERVICE.Personalizada]?.fullHoraMax);
			},
		},
	);

	const { mutate: handleSaveReservationsConfig, isLoading: loadingSaveReservationsConfig } = useMutation(
		workstationService.updateReservationsConfig,
		{
			onSuccess: () => {
				handleSaveDefaultSedePolicyConfig(sedeDefaultPoliciesConfigCurrent);
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const { isLoading: isLoadingSedeDefaultPolicyConfig, data: initialSedeDefaultPolicyConfig } = useQuery(
		['sedeDefaultPolicyConfig'],
		async () => workstationService.getDefaultSedePolicy(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setSedeDefaultPoliciesConfig(data);
			},
		},
	);

	const { mutate: handleSaveDefaultSedePolicyConfig, isLoading: loadingSaveDefaultSedePolicyConfig } = useMutation(
		workstationService.updateDefaultSedePolicy,
		{
			onSuccess: () => {
				handleSaveTurningPointsConfig(turningPointsConfig);
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const { isLoading: isLoadingTurningPointsConfig, data: initialTurningPointsConfig } = useQuery(
		['turningPointsConfig'],
		async () => workstationService.getTurningPointsConfig(),
		{
			refetchOnWindowFocus: false,
			onError: (error) => setError(error as string),
			onSuccess: (data) => {
				setTurningPointsConfig(data);
			},
		},
	);

	const { mutate: handleSaveTurningPointsConfig, isLoading: loadingSaveTurningPointsConfig } = useMutation(
		workstationService.updateTurningPointsConfig,
		{
			onSuccess: () => {
				if (!_.isEqual(initialTurningPointsConfig?.buildings, turningPointsConfig?.buildings)) {
					workstationService.sendBuildingOpeningEmails(sedePortal.id);
				}
				if (
					initialReservationsConfig?.favouriteDesksRestriction === false &&
					reservationsConfig?.favouriteDesksRestriction === true
				) {
					workstationService.deleteFavouriteDesks(sedePortal.id);
				}
				setSuccess(t('msg_success'));
				queryClient.refetchQueries('reservationsConfig');
				queryClient.refetchQueries('sedeDefaultPolicyConfig');
				queryClient.refetchQueries('turningPointsConfig');
			},
			onError: (error) => {
				setError(error as string);
			},
		},
	);

	const isLoading = useMemo(
		() =>
			isLoadingReservationsConfig ||
			isLoadingSedeDefaultPolicyConfig ||
			isLoadingTurningPointsConfig ||
			loadingSaveReservationsConfig ||
			loadingSaveDefaultSedePolicyConfig ||
			loadingSaveTurningPointsConfig,
		[
			isLoadingReservationsConfig,
			isLoadingSedeDefaultPolicyConfig,
			isLoadingTurningPointsConfig,
			loadingSaveReservationsConfig,
			loadingSaveDefaultSedePolicyConfig,
			loadingSaveTurningPointsConfig,
		],
	);

	const isEdited = useMemo(() => {
		return (
			!_.isEqual(initialReservationsConfig, reservationsConfig) ||
			!_.isEqual(initialSedeDefaultPolicyConfig, sedeDefaultPoliciesConfig) ||
			!_.isEqual(initialTurningPointsConfig, turningPointsConfig)
		);
	}, [
		initialReservationsConfig,
		reservationsConfig,
		initialSedeDefaultPolicyConfig,
		sedeDefaultPoliciesConfig,
		initialTurningPointsConfig,
		turningPointsConfig,
	]);

	const minAndMaxHoursAreValid = useMemo(() => {
		return (
			(policiesAvailableInThisSede &&
				!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.personalized)) ||
			validateHours(minReservationHour, maxReservationHour)
		);
	}, [policiesAvailableInThisSede, minReservationHour, maxReservationHour]);

	const defaultHoursAreValid = useMemo(() => {
		if (sedeDefaultPoliciesConfigCurrent?.id !== SEDE_POLICIES.personalized) {
			return true;
		}

		return validateHours(
			sedeDefaultPoliciesConfigCurrent?.fullHoraMin,
			sedeDefaultPoliciesConfigCurrent?.fullHoraMax,
		);
	}, [
		sedeDefaultPoliciesConfigCurrent?.id,
		sedeDefaultPoliciesConfigCurrent?.fullHoraMin,
		sedeDefaultPoliciesConfigCurrent?.fullHoraMax,
	]);

	const saveIsDisabled = useMemo(() => {
		return !isEdited || isLoading || !minAndMaxHoursAreValid || !defaultHoursAreValid;
	}, [isEdited, isLoading, minAndMaxHoursAreValid, defaultHoursAreValid]);

	const policiesAvailableOptions = useMemo(
		() =>
			Object.values(POLICIES_AVAILABLE_OPTIONS).map((value) => ({
				value: value,
				label: t(`lbl_reservations_policies_options.${value}`),
			})),
		[],
	);

	const availableOptions = useMemo(
		() => getAvailableOptions(sedeDefaultPoliciesConfig, policiesAvailableInThisSede),
		[sedeDefaultPoliciesConfig, policiesAvailableInThisSede],
	);

	const selectedOption = useMemo(
		() => getSelectedOption(sedeDefaultPoliciesConfig, policiesAvailableInThisSede),
		[sedeDefaultPoliciesConfig, policiesAvailableInThisSede],
	);

	useEffect(() => {
		setThereAreUnsavedChanges(isEdited);
	}, [isEdited]);

	const handleScheduleChange = (e: any) => {
		if (_.isEqual(reservationsConfig, defaultWorkstationReservationsConfig)) {
			return;
		}

		const { name, value } = e.target;

		const updatedPolicies = reservationsConfig.policies.map((policy) => {
			if (policy.name === POLICIES_SERVICE.Morning && name === 'middayHour') {
				setMiddayHour(value);
				return {
					...policy,
					fullHoraMax: value,
				};
			}
			if (policy.name === POLICIES_SERVICE.Tarde && name === 'middayHour') {
				setMiddayHour(value);
				return {
					...policy,
					fullHoraMin: value,
				};
			} else if (policy.name === POLICIES_SERVICE.Personalizada && name === 'minReservationHour') {
				setMinReservationHour(roundHour(value));
				return {
					...policy,
					fullHoraMin: roundHour(value),
				};
			} else if (policy.name === POLICIES_SERVICE.Personalizada && name === 'maxReservationHour') {
				setMaxReservationHour(roundHour(value));
				return {
					...policy,
					fullHoraMax: roundHour(value),
				};
			} else {
				return policy;
			}
		});

		setReservationsConfig({ ...reservationsConfig, policies: updatedPolicies });
	};

	const updateMaxFavoriteDesks = (value: number) => {
		setReservationsConfig({
			...reservationsConfig,
			maxFavoriteDesks: value,
		});
	};

	const updateLastHistoricDesks = (value: number) => {
		setReservationsConfig({
			...reservationsConfig,
			lastHistoricDesks: value,
		});
	};

	const handleToggleFavouritesRestriction = () => {
		if (_.isEqual(reservationsConfig, defaultWorkstationReservationsConfig)) {
			return;
		}

		setReservationsConfig({
			...reservationsConfig,
			favouriteDesksRestriction: !reservationsConfig.favouriteDesksRestriction,
		});
	};
	const handleTogglePresenceMandatoryRestriction = () => {
		if (_.isEqual(reservationsConfig, defaultWorkstationReservationsConfig)) {
			return;
		}
		setReservationsConfig((prevConfig) => ({
			...prevConfig,
			isPresenceMandatory: !reservationsConfig.isPresenceMandatory,
		}));
	};

	const updatePoliciesSelection = (newSelectedOption: string) => {
		let nextAvailableOptions = [];

		if (!policiesAvailableInThisSede.includes(newSelectedOption)) {
			nextAvailableOptions = [...policiesAvailableInThisSede, newSelectedOption];
		} else if (policiesAvailableInThisSede.includes(newSelectedOption) && policiesAvailableInThisSede.length === 1) {
			nextAvailableOptions = [newSelectedOption];
		} else {
			nextAvailableOptions = policiesAvailableInThisSede.filter((option) => option !== newSelectedOption);
		}

		setPoliciesAvailableInThisSede(nextAvailableOptions);

		setReservationsConfig({
			...reservationsConfig,
			policies: getUpdatedPolicies(reservationsConfig.policies, nextAvailableOptions),
		});
	};

	const handleUpdateDefaultPolicy = (id: number) => {
		setSedeDefaultPoliciesConfig((prev) =>
			prev.map((item) => ({
				...item,
				isDefault: item.id === id ? true : false,
				isActive: item.id === id ? true : false,
			})),
		);
	};

	const handleUpdateDefaultPolicyMinHour = (id: number, hour: string) => {
		setSedeDefaultPoliciesConfig((prev) =>
			prev.map((item) => ({
				...item,
				fullHoraMin: item.id === id ? hour : item.fullHoraMin,
			})),
		);
	};

	const handleUpdateDefaultPolicyMaxHour = (id: number, hour: string) => {
		setSedeDefaultPoliciesConfig((prev) =>
			prev.map((item) => ({
				...item,
				fullHoraMax: item.id === id ? hour : item.fullHoraMax,
			})),
		);
	};

	const handleOnSave = () => {
		const availablePoliciesIds = availableOptions.map((option) => option?.id);

		if (!availablePoliciesIds.includes(sedeDefaultPoliciesConfigCurrent.id)) {
			setShowInvalidDefaultScheduleConfigurationModal(true);
			return;
		}

		if (sedeDefaultPoliciesConfigCurrent.id === SEDE_POLICIES.personalized) {
			const sedeDefaultPolicyHoursAreValid =
				validateHours(sedeDefaultPoliciesConfigCurrent.fullHoraMin, sedeDefaultPoliciesConfigCurrent.fullHoraMax) &&
				validateHours(minReservationHour, sedeDefaultPoliciesConfigCurrent.fullHoraMin, 'isEqualOrGreater') &&
				validateHours(maxReservationHour, sedeDefaultPoliciesConfigCurrent.fullHoraMax, 'isEqualOrLess');
			if (!sedeDefaultPolicyHoursAreValid) {
				setShowInvalidScheduleConfigurationModal(true);
				return;
			}
		} else if (reservationsConfig.favouriteDesksRestriction && !initialReservationsConfig.favouriteDesksRestriction) {
			setShowFavouritesRestrictionModal(true);
			return;
		}
		confirmSave();
	};

	const confirmSave = () => {
		setShowFavouritesRestrictionModal(false);
		if (
			hasEmptyValues(reservationsConfig) ||
			!sedeDefaultPoliciesConfigCurrent ||
			hasEmptyValues(sedeDefaultPoliciesConfig) ||
			hasEmptyValues(turningPointsConfig)
		) {
			setError(t('msg_error_all_fields_required'));
		} else {
			handleSaveReservationsConfig(reservationsConfig);
		}
	};

	return (
		<>
			{isLoadingReservationsConfig || isLoadingTurningPointsConfig ? (
				<IonLoading isOpen={isLoading} message={t('msg_loading')} duration={0} />
			) : (
				<>
					{/* -------- BOOKINGS CONFIGURATION -------------------------------------------------------------- */}

					<List>
						{/* TURNING POINTS CONFIGURATION */}
						<TurningPointsConfiguration
							data={turningPointsConfig}
							updateData={setTurningPointsConfig}
							hasWritePermission={hasWritePermission}
						/>
						<header className={`${styles.h2} ${styles.headerSpace}`}>
							{t('title_timeAndReservations_reservation_configuration')}
						</header>
						{/* PRESENCE MANDATORY */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_timeAndReservations_presence_mandatory_restriction')}
							</div>
							<IonItem lines="none" className={`${styles.inputWithLabel} ${styles.bottomLine}`}>
								<IonLabel className={styles.labelInput}>
									{t('lbl_timeAndReservations_presence_mandatory_apply_restriction')}
								</IonLabel>
								<Toggle
									checked={reservationsConfig.isPresenceMandatory}
									onChange={handleTogglePresenceMandatoryRestriction}
									disabled={!hasWritePermission}
								/>
							</IonItem>
						</div>
						{/* DURATION */}
						<div className={styles.element}>
							<p className={styles.blockSubtitle}>
								{t(`lbl_timeAndReservations_reservation_types_description`)}
							</p>
							<MultiSelect
								className={`${styles.multiSelect} ${
									policiesAvailableInThisSede.length > 0 && styles.touchedInput
								}`}
								label={t('lbl_duration')}
								options={policiesAvailableOptions}
								selected={policiesAvailableInThisSede}
								updateSelection={updatePoliciesSelection}
								disabled={!hasWritePermission}
							/>
						</div>
						{/* MORNING / AFTERNOON TURNING POINT */}
						<div className={styles.element}>
							<div className={styles.blockSubtitle}>
								{t('lbl_timeAndReservations_morning_turning_point_description')}
							</div>
							<HourSelector
								name="middayHour"
								label={t('lbl_hour_selector')}
								value={middayHour}
								disabled={
									!hasWritePermission ||
									!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.halfDays)
								}
								onChange={handleScheduleChange}
							/>
						</div>
						{/* MIN AND MAX RESERVATION HOUR */}
						<div className={styles.element}>
							<div className={styles.blockSubtitle}>
								{t('lbl_timeAndReservations_min_and_max_reservation_hour')}
							</div>
							<div className={styles.multipleInputsContainer}>
								<HourSelector
									name="minReservationHour"
									label={t('lbl_start_hour')}
									value={minReservationHour}
									disabled={
										!hasWritePermission ||
										!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.personalized)
									}
									isValid={minAndMaxHoursAreValid}
									onChange={handleScheduleChange}
								/>
								<HourSelector
									name="maxReservationHour"
									label={t('lbl_end_hour')}
									value={maxReservationHour}
									disabled={
										!hasWritePermission ||
										!policiesAvailableInThisSede.includes(POLICIES_AVAILABLE_OPTIONS.personalized)
									}
									isValid={minAndMaxHoursAreValid}
									onChange={handleScheduleChange}
								/>
							</div>
						</div>
						{/* DEFAULT PREFERENCES CONFIGURATION */}
						<DefaultPreferencesConfiguration
							sedePolicies={SEDE_POLICIES}
							handleUpdateDefaultPolicy={handleUpdateDefaultPolicy}
							handleUpdateMinHour={handleUpdateDefaultPolicyMinHour}
							handleUpdateMaxHour={handleUpdateDefaultPolicyMaxHour}
							availableOptions={availableOptions}
							selectedOption={selectedOption}
							hasWritePermission={hasWritePermission}
						/>
						{/* MAX FAVOURITE DESKS */}
						<div className={`${styles.h2} ${styles.headerSpace}`}>
							{t('lbl_timeAndReservations_favorite_workstations')}
						</div>
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_timeAndReservations_favorite_workstations_description')}
							</div>
							<NumberInput
								name="maxFavoriteDesks"
								className={`ion-text-end`}
								value={reservationsConfig.maxFavoriteDesks}
								onIonChange={(e: InputCustomEvent) => updateMaxFavoriteDesks(+e.target.value)}
								min={0}
								disabled={!hasWritePermission}
								aria-label={t('quantity')}
								label={t('quantity')}
								required
							></NumberInput>
						</div>
						{/* RESTRICTIONS */}
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_timeAndReservations_favorite_workstations_restrictions')}
							</div>
							<IonItem lines="none" className={`${styles.inputWithLabel} ${styles.bottomLine}`}>
								<IonLabel className={styles.labelInput}>
									{t('lbl_timeAndReservations_favorite_workstations_apply_restriction')}
								</IonLabel>
								<Toggle
									checked={reservationsConfig.favouriteDesksRestriction}
									onChange={handleToggleFavouritesRestriction}
									disabled={!hasWritePermission}
								/>
							</IonItem>
						</div>
						{/* HISTORIC WORKSTATIONS */}
						<div className={`${styles.h2} ${styles.headerSpace}`}>
							{t('lbl_timeAndReservations_historic_workstations')}
						</div>
						<div className={styles.element}>
							<div className={`${styles.footnote} ${styles.space}`}>
								{t('lbl_timeAndReservations_historic_workstations_description')}
							</div>
							<NumberInput
								name="lastHistoricDays"
								className="ion-text-end"
								value={reservationsConfig.lastHistoricDesks}
								onIonChange={(e: InputCustomEvent) => updateLastHistoricDesks(+e.target.value)}
								min={0}
								disabled={!hasWritePermission}
								required
								aria-label={t('quantity')}
								label={t('quantity')}
							></NumberInput>
						</div>
					</List>
					{/* -------- FOOTER -------------------------------------------------------- */}
					{hasWritePermission && (
						<div className={`${props.footerStyle} ${styles.footerButton}`}>
							<Button
								onClick={handleOnSave}
								className={styles.btnHeader}
								disabled={saveIsDisabled}
								color="primary"
							>
								{t('btn_save_data')}
							</Button>
						</div>
					)}
				</>
			)}
			<CustomModal
				showModal={showFavouritesRestrictionModal}
				onConfirm={confirmSave}
				onClose={() => setShowFavouritesRestrictionModal(false)}
				title={t('lbl_timeAndReservations_restriction_activated_modal_title')}
				description={t('lbl_timeAndReservations_restriction_activated_modal_description')}
			/>
			<CustomModal
				showModal={showInvalidDefaultScheduleConfigurationModal}
				onConfirm={() => setShowInvalidDefaultScheduleConfigurationModal(false)}
				onClose={() => setShowInvalidDefaultScheduleConfigurationModal(false)}
				title={t('lbl_timeAndReservations_invalid_schedule_configuration_modal_title')}
				description={t('lbl_timeAndReservations_invalid_default_schedule_configuration_modal_description')}
				confirmText={t('btn_accept')}
				cancelText={t('btn_cancel')}
			/>
			<CustomModal
				showModal={showInvalidScheduleConfigurationModal}
				onConfirm={() => setShowInvalidScheduleConfigurationModal(false)}
				onClose={() => setShowInvalidScheduleConfigurationModal(false)}
				title={t('lbl_timeAndReservations_invalid_schedule_configuration_modal_title')}
				description={t('lbl_timeAndReservations_invalid_schedule_configuration_modal_description')}
				confirmText={t('btn_accept')}
				cancelText={t('btn_cancel')}
			/>
		</>
	);
};
