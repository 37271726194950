import { useEffect, useState } from 'react';
import {
	IonToolbar,
	IonTitle,
	IonContent,
	IonText,
	IonFooter,
	IonLabel,
	IonRadioGroup,
	IonItem,
	IonRadio,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Modal } from '@acciona/ui-ionic-kit';
import _ from 'lodash';
import { BuildingTurningPointsConfig } from '../../../../../_api/services/workstation/types';
import { DaySelector } from './DaySelector';
import styles from './styles.module.scss';
import { NumberInput } from '../../../../../components/NumberInput/NumberInput';

type Props = {
	data: BuildingTurningPointsConfig;
	showModal: boolean;
	onClose: () => void;
	onSave: (configData: BuildingTurningPointsConfig) => void;
};

export const ModalTurningPointConfiguration: React.FC<Props> = (props) => {
	const { data: initialData, showModal, onClose, onSave } = props;
	const { t } = useTranslation();
	const [data, setData] = useState<BuildingTurningPointsConfig>(null);
	const [turningPointVisibilityDaysOption, setTurningPointVisibilityDaysOption] = useState<number>(null);
	const [isEdited, setIsEdited] = useState(false);
	const [saveIsDisabled, setSaveIsDisabled] = useState(false);

	useEffect(() => {
		setData(initialData);
	}, [initialData]);

	useEffect(() => {
		if (data) setTurningPointVisibilityDaysOption(data?.numberOpenDays !== 0 ? 1 : 0); // 0 = only current day // 1 = several days
	}, [data?.numberOpenDays]);

	const onCloseHandler = () => {
		onClose();
	};

	const handleSaveChanges = () => {
		onSave(data);
	};

	const updateValues = (nextValues: BuildingTurningPointsConfig) => {
		setData(nextValues);
		setIsEdited(!_.isEqual(initialData, nextValues));
		if (
			nextValues?.dayOfWeek &&
			nextValues.dayOfWeek.some(
				({ day, hour }) => day === null || day === undefined || hour === null || hour === undefined,
			)
		) {
			setSaveIsDisabled(true);
		} else {
			setSaveIsDisabled(false);
		}
	};

	const addTurningPointHandler = () => {
		const nextData: BuildingTurningPointsConfig = {
			...data,
			dayOfWeek: [
				...(data?.dayOfWeek ? data.dayOfWeek : []),
				{
					day: null,
					hour: null,
				},
			],
		};
		updateValues(nextData);
	};

	const deleteTurningPointHandler = (weekday: number): void => {
		const nextData: BuildingTurningPointsConfig = {
			...data,
			dayOfWeek: data?.dayOfWeek.filter((day) => day.day !== weekday),
		};
		updateValues(nextData);
	};

	const updateDayHandler = (day: number, weekday?: number): void => {
		const nextData: BuildingTurningPointsConfig = {
			...data,
			dayOfWeek: data?.dayOfWeek.map((d) =>
				d.day === weekday
					? {
							...d,
							day: day,
					  }
					: d,
			),
		};
		updateValues(nextData);
	};

	const updateHourHandler = (hour: string, weekday?: number): void => {
		const nextData: BuildingTurningPointsConfig = {
			...data,
			dayOfWeek: data?.dayOfWeek.map((d) =>
				d.day === weekday
					? {
							...d,
							hour: hour,
					  }
					: d,
			),
		};
		updateValues(nextData);
	};

	const updateVisibleDays = (days: number) => {
		const nextData: BuildingTurningPointsConfig = {
			...data,
			numberOpenDays: days,
		};
		updateValues(nextData);
	};
	const turningPointVisibilityDaysOptions = [
		{ id: 0, configurationName: t('only_current_day') },
		{ id: 1, configurationName: t('several_days') },
	];
	return (
		<Modal isOpen={showModal} onDidDismiss={onCloseHandler} className={styles.modal}>
			<IonToolbar>
				<Button
					slot="end"
					fill={'clear'}
					color="light"
					className={styles.closeModalBtn}
					aria-label={t('lbl_aria_close_modal')}
					onClick={onCloseHandler}
				>
					<Icon className="icon icon-close" aria-hidden="true"></Icon>
				</Button>
				<IonTitle className={styles.toolbarTitle}>{`${data?.buildingName} - ${t(
					'lbl_timeAndReservations_turning_point_modify',
				)}`}</IonTitle>
			</IonToolbar>
			<IonContent scrollY={true}>
				<IonText className={`${styles.textInstructions} ion-text-wrap block`}></IonText>

				<div className={styles.element}>
					<div className={styles.h3}>{t('lbl_timeAndReservations_turning_point_visibility_days_title')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>
						{t('lbl_timeAndReservations_turning_point_visibility_days_description')}
					</div>
					<IonRadioGroup
						className={styles.turningPointVisibilityDaysOptions}
						value={turningPointVisibilityDaysOption}
						onIonChange={(e) => {
							setTurningPointVisibilityDaysOption(e.target.value);
							updateVisibleDays(e.target.value);
						}}
					>
						{turningPointVisibilityDaysOptions.map((option) => (
							<IonItem key={option.id} className={styles.selector} lines="none">
								<IonLabel>{t(option.configurationName)}</IonLabel>
								<IonRadio slot="start" mode="md" value={option.id} aria-label={t(option.configurationName)} />
							</IonItem>
						))}
					</IonRadioGroup>
					{turningPointVisibilityDaysOption != 0 && (
						<NumberInput
							name="turningPointVisibilityDays"
							className={`ion-text-end`}
							value={data?.numberOpenDays}
							onIonChange={(e) => updateVisibleDays(+e.target.value)}
							min={0}
							disabled={false}
							required={true}
							aria-label={t('quantity')}
							label={t('quantity')}
						></NumberInput>
					)}
				</div>

				<div className={styles.element}>
					<div className={styles.h3}>{t('lbl_timeAndReservations_turning_point')}</div>
					<div className={`${styles.footnote} ${styles.space}`}>
						{t('lbl_timeAndReservations_turning_point_enable_reservations_day')}
					</div>
					{!_.isEmpty(data?.dayOfWeek) &&
						data.dayOfWeek.map((turningPoint) => (
							<DaySelector
								key={turningPoint.day}
								updateHour={updateHourHandler}
								updateDay={updateDayHandler}
								onDelete={deleteTurningPointHandler}
								selectedDays={data?.dayOfWeek}
								turningPoint={turningPoint}
							/>
						))}
					{_.isEmpty(data?.dayOfWeek) ||
						((!_.isEmpty(data?.dayOfWeek) && data.dayOfWeek.length) < 7 && (
							<Button
								slot="start"
								className={`${styles.btnHeader} ${styles.secondaryBtn} ${styles.btnAddTurningPoint}`}
								onClick={addTurningPointHandler}
							>
								<Icon className="icon icon-plus iconPlus" />
								<IonLabel>{t('btn_add_new')}</IonLabel>
							</Button>
						))}
				</div>
			</IonContent>
			<IonFooter className={styles.btnModal}>
				<div>
					<Button className={`${styles.btnHeader} ${styles.secondaryBtn}`} onClick={onCloseHandler}>
						{t('btn_cancel')}
					</Button>
				</div>
				<div>
					<Button
						color="primary"
						className={styles.btnHeader}
						onClick={handleSaveChanges}
						disabled={!isEdited || saveIsDisabled}
					>
						{t('btn_accept')}
					</Button>
				</div>
			</IonFooter>
		</Modal>
	);
};
