import muleSoftInstance from '../../utils/httpCommon';
import { AppPermissionsData, Permissions } from './types';

const getPortalEmployees = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role/employees`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getPortal = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const modifyPortal = async (roles): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role`;
	try {
		const resp = await muleSoftInstance.put(path, roles);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

//Roles App MASH
const getAppEmployees = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role/mash/employees`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getAppProfilesList = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role/mash/profiles`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getApp = async (): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role/mash`;
	try {
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const modifyApp = async (permissions: Permissions[]): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/role/mash`;
	try {
		const resp = await muleSoftInstance.put(path, permissions);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const getAppPermissions = async (permission: string): Promise<AppPermissionsData> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/permissions/mash`;

	try {
		const resp = await muleSoftInstance.get(path, {
			params: { permission: permission },
		});

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

const updateAppPermissions = async (permissionsData: AppPermissionsData): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/v1/permissions/mash`;
	try {
		const resp = await muleSoftInstance.put(path, permissionsData);
		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const roleService = {
	getPortalEmployees,
	getPortal,
	modifyPortal,
	getAppEmployees,
	getAppProfilesList,
	getApp,
	modifyApp,
	getAppPermissions,
	updateAppPermissions,
};
