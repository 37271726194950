import muleSoftInstance from '../../utils/httpCommon';
import { HolidaysAndDisabledDays } from './types';

const getHolidaysAndDisabledDays = async () => {
	try {
		const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/reservableDays`;
		const resp = await muleSoftInstance.get(path);
		return resp.data;
	} catch (error) {
		throw new Error(error.message);
	}
};
const saveHolidaysAndDisabledDays = async (form: HolidaysAndDisabledDays): Promise<any> => {
	const path = `${process.env.REACT_APP_API_PORTAL}/configuration/desk/reservableDays`;
	try {
		const resp = await muleSoftInstance.put(path, form);

		return resp.data;
	} catch (err) {
		throw new Error(err);
	}
};

export const transversalServices = { getHolidaysAndDisabledDays, saveHolidaysAndDisabledDays };
