import { useState } from 'react';
import { ReorderGroup, Reorder } from '@acciona/ui-ionic-kit';
import { IonButton, IonLabel, IonItem, IonText, IonIcon, IonPopover, IonContent, IonList } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { District } from '../../_api/services/workstation/types';
import CustomModal from '../CustomModal/CustomModal';
import styles from './styles.module.scss';

type Props = {
	hasWritePermission: boolean;
	onItemReorder?: Function;
	selectedLang?: string;
	districtsConfig: District[];
	handlerEditZone?: Function;
	handlerDeleteSubcat?: Function;
};

export const ZoneList: React.FC<Props> = (props) => {
	const { hasWritePermission, districtsConfig, handlerEditZone, handlerDeleteSubcat } = props;
	const { t } = useTranslation();
	const [openDeleteZoneModal, setOpenDeleteZoneModal] = useState(false);
	const [zoneSelectedToDelete, setZoneSelectedToDelete] = useState('');

	return (
		<>
			<ReorderGroup disabled>
				{districtsConfig
					.sort((a, b) => {
						const buildingComparison = a.buildingName.localeCompare(b.buildingName);
						if (buildingComparison === 0) {
							return a.floorNumber - b.floorNumber;
						}
						return buildingComparison;
					})
					.map((item) => {
						return (
							<Reorder key={`${item.districtName}${item.buildingName}${item.floorName}`}>
								<IonItem>
									<IonLabel className={`ion-text-wrap`}>
										<IonText className={`${styles.body} ion-text-wrap block`}>
											{item.districtName} - {item.buildingName}, {item.floorName}
										</IonText>
										<IonText className={`${styles.footnote} ion-text-wrap block`}>
											{item.overflowDistricts.map((zone, index) => {
												return `${zone}${index !== item.overflowDistricts.length - 1 ? ', ' : ''}`;
											})}
										</IonText>
									</IonLabel>
									{hasWritePermission && (
										<>
											<IonButton
												slot="end"
												fill="clear"
												id={`popover-${item.districtName}`}
												className={styles.buttonEdit}
											>
												<IonIcon slot="end" className="icon icon-ellipsis-vertical"></IonIcon>
											</IonButton>
											<IonPopover
												trigger={`popover-${item.districtName}`}
												triggerAction="click"
												dismissOnSelect={true}
												side="bottom"
												alignment="end"
												className={styles.popoverSty}
											>
												<IonContent>
													<IonList className={styles.listPadding}>
														<IonItem
															lines="full"
															button={true}
															onClick={() => {
																handlerEditZone(item.districtName);
															}}
															detail={false}
														>
															<IonLabel className={styles.body}>{t('lbl_modify')}</IonLabel>
														</IonItem>

														<IonItem
															lines="full"
															button={true}
															onClick={() => {
																setZoneSelectedToDelete(item.districtName);
																setOpenDeleteZoneModal(true);
															}}
															detail={false}
														>
															<IonLabel className={styles.body}>{t('delete_text')}</IonLabel>
														</IonItem>
													</IonList>
												</IonContent>
											</IonPopover>
										</>
									)}
								</IonItem>
							</Reorder>
						);
					})}
			</ReorderGroup>

			<CustomModal
				showModal={openDeleteZoneModal}
				onConfirm={() => {
					handlerDeleteSubcat(zoneSelectedToDelete);
					setOpenDeleteZoneModal(false);
				}}
				onClose={() => {
					setOpenDeleteZoneModal(false);
					setZoneSelectedToDelete('');
				}}
				title={t('district_delete_modal.title')}
				description={t('district_delete_modal.description')}
			></CustomModal>
		</>
	);
};
